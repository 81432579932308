import React from "react";
import { Modal, Form, Input, message } from "antd";
import { adminsUpdate } from "../../../../services/apiServices/admins_api";

const AdminEditModal = (props) => {
  const {
    setIsEditModalVisible,
    isEditModalVisible,
    selectedElement,
    form,
    setReload,
    reload,
  } = props;
  const handleOkEditModal = () => {
    form.submit();
    setIsEditModalVisible(false);
  };

  const handleCancelEditModal = () => {
    form.resetFields();
    setIsEditModalVisible(false);
  };
  const onFinish = (values) => {
    let elementId = form.getFieldValue("id");
    (async () => {
      const response = await adminsUpdate(elementId, values);
      if (response) {
        message.success("Ma`lumot saqlandi");
        setReload(!reload);
      }
    })();
  };
  const onFinishFailed = (err) => {};
  return (
    <>
      <Modal
        title="Edit admin"
        open={isEditModalVisible}
        onOk={handleOkEditModal}
        onCancel={handleCancelEditModal}
        okText={"save"}
        cancelText={"cancel"}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="FIO"
            name="fio"
            rules={[{ required: true, message: "Please input role name!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AdminEditModal;
