import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { getNoGroupStudents } from "../../../../services/apiServices/students_api";

const AllStudentsTable = (props) => {
  const {
    allStudents,
    setAllStudents,
    selectedStudents,
    setSelectedStudents,
    setSelectedKeys,
    selectedKeys,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const res = await getNoGroupStudents({ show_count: "all" });
      if (res) {
        setAllStudents(res?.data);
      }
      setIsLoading(false);
    })();
  }, []);
  let columns = [
    {
      title: "F.I.O",
      dataIndex: "fio",
      key: "fio",
    },
    {
      title: "Unikal raqami",
      dataIndex: "unique_number",
      key: "unique_number",
    },
  ];
  const rowSelection = {
    selectedRows: selectedStudents,
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      let items = [...new Set([...selectedStudents, ...selectedRows])];
      let keys = [...new Set([...selectedKeys, ...selectedRowKeys])];

      setSelectedStudents(items);
      setSelectedKeys(keys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  return (
    <>
      <Table
        style={{ maxHeight: "60vh", overflow: "hidden", overflowY: "scroll" }}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
        dataSource={allStudents}
        loading={isLoading}
        columns={columns}
        pagination={true}
        rowKey={"unique_number"}
      />
    </>
  );
};

export default AllStudentsTable;
