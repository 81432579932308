import React, {useState} from 'react'
import {Layout, Menu} from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import "./styles.css"
import Sidebar from "../sidebar/Sidebar";
import MyHeader from "../header/MyHeader";

const {Header, Sider, Content} = Layout;


const MyLayout = ({children}) => {

    const [collapsed, setCollapsed] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };
    return (
        <Layout>
            <Sidebar collapsed={collapsed}/>
            <Layout className="site-layout">
                <MyHeader collapsed={collapsed} setCollapsed={setCollapsed} />
                <Content
                    className="site-layout-background"
                    style={{
                        margin: '10px 10px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    {children}
                </Content>
            </Layout>
        </Layout>
    );
}
export default MyLayout