import React from "react";
import { Modal, Form, Input, message } from "antd";
import { organizationsUpdate } from "../../../../services/apiServices/organizations_api";

const OrganizationEditModal = (props) => {
  const {
    setIsEditModalVisible,
    isEditModalVisible,
    selectedElement,
    form,
    setReload,
    reload,
  } = props;
  const handleOkEditModal = () => {
    form.submit();
    setIsEditModalVisible(false);
  };

  const handleCancelEditModal = () => {
    form.resetFields();
    setIsEditModalVisible(false);
  };
  const onFinish = (values) => {
    let elementId = form.getFieldValue("id");
    (async () => {
      const response = await organizationsUpdate(elementId, values);
      if (response) {
        message.success("Ma`lumot saqlandi");
        setReload(!reload);
      }
    })();
  };
  const onFinishFailed = (err) => {};
  return (
    <>
      <Modal
        title="Edit organization"
        open={isEditModalVisible}
        onOk={handleOkEditModal}
        onCancel={handleCancelEditModal}
        okText={"save"}
        cancelText={"cancel"}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input role name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Please input phone!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input address!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Id"
            name="id"
            hidden={true}
            rules={[{ required: true, message: "Please input address!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OrganizationEditModal;
