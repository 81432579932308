import React, {useContext} from 'react'
import Cookies from 'universal-cookie';
import {message} from "antd";
import API from "../../api";

export const generalApi = async (params = {},url) => {
    try {
        const response = await API({
            url: url,
            method: 'GET',
            params: params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
