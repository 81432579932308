import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  studentEndExam,
  studentStartExam,
} from "../../../../services/apiServices/student_exam_api";
import { Radio, Form, Button, Col, Row, message, Statistic } from "antd";
import moment from "moment";
import MathJaxWrapper from "../../../../components/Editor/mathJax";

const ExamProcess = () => {
  const { Countdown } = Statistic;
  const location = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState();
  const navigate = useNavigate();
  const examId = location?.id;
  let answerKeys = ["A", "B", "C", "D", "E", "F"];
  const onFinishTimer = () => {
    form.submit();
  };

  const onChangeTimer = (val) => {
    if (4.95 * 1000 < val && val < 5 * 1000) {
      console.log("changed!");
    }
  };
  useEffect(() => {
    (async () => {
      const data = new FormData();
      data.append("exam_id", location?.id);
      const response = await studentStartExam(data, {});
      if (response?.status === 1) {
        setData(response?.result);
      }
    })();
  }, []);
  const onFinish = (values) => {
    // if (window.confirm('Imtihonni tugatib hamma javoblarni saqlaysizmi ?')){
    let data = new FormData();
    data.append("answers", JSON.stringify(values));
    data.append("exam_id", examId);
    (async () => {
      const response = await studentEndExam(data, {});
      if (response?.status === 1) {
        message.success("Saqlandi");
        navigate("/student/exams");
      }
    })();
    // }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const endTest = () => {
    form.submit();
  };
  // window.onbeforeunload = (event) => {
  //     const e = event || window.event;
  //     // Cancel the event
  //     e.preventDefault();
  //     if (e) {
  //         e.returnValue = 'Sahifani yopish bilan testdan chiqgan hisoblanasiz'; // Legacy method for cross browser support
  //     }
  //     return 'Sahifani yopish bilan testdan chiqgan hisoblanasiz'; // Legacy method for cross browser support
  // };
  return (
    <>
      <Form
        form={form}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row className={"d-flex justify-content-center mb-5"}>
          <Col xl={12} lg={16} md={20} sm={24} className={"border"}>
            {data?.body &&
              data?.body?.map((element, index) => {
                return (
                  <div className={"w-100 border-bottom p-2"}>
                    <div className={"d-flex"}>
                      <div>
                        <b>{index + 1})</b>
                      </div>
                      <MathJaxWrapper>
                        <div
                          dangerouslySetInnerHTML={{ __html: element?.content }}
                        />
                      </MathJaxWrapper>
                    </div>
                    <div>
                      <Form.Item
                        label=""
                        name={`answer.${element?.id}`}
                        initialValue={
                          element?.selected_answer_id
                            ? element?.selected_answer_id
                            : ""
                        }
                      >
                        <Radio.Group>
                          {element?.answers &&
                            element?.answers.map(
                              (elementAnswer, indexAnswer) => {
                                return (
                                  <div className={"d-flex "}>
                                    <Radio
                                      value={elementAnswer?.id}
                                      key={indexAnswer}
                                    >
                                      <div className={"d-flex"}>
                                        <b> {answerKeys[indexAnswer]}) </b>
                                        <MathJaxWrapper>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: elementAnswer?.content,
                                            }}
                                          />
                                        </MathJaxWrapper>
                                      </div>
                                    </Radio>
                                  </div>
                                );
                              }
                            )}
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </div>
                );
              })}
            <Button onClick={endTest} className={"w-100 btn-primary"}>
              Imtihonni tugatish
            </Button>
          </Col>
          <Col xl={6} lg={6} md={20} sm={24} className={"p-2 border"}>
            <div className={"d-flex"}>
              <span>Imtihon nomi: </span> <b> &nbsp; {data?.exam?.name}</b>
            </div>
            <div className={""}>
              <div>Urinish boshlash va tugash vaqti:</div>
              <b>
                {" "}
                &nbsp; {moment(data?.attempt?.start_time).format(
                  "HH:mm:ss"
                )} - {moment(data?.attempt?.end_time).format("HH:mm:ss")}
              </b>
            </div>
            <div>
              <div>Qolgan vaqt:</div>
              {data?.attempt?.end_time && (
                <Countdown
                  title=""
                  value={new Date(data?.attempt?.end_time).getTime() - 1000}
                  onFinish={onFinishTimer}
                />
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ExamProcess;
