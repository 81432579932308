import React,{useEffect,useState} from 'react'
import {Row,Col,Button} from "antd";
import QuestionDegreeAddModal from "./Modals/QuestionDegreeAddModal";
import QuestionDegreeTable from "./QuestionDegreeTable";
import {questionDegreeIndex} from "../../services/apiServices/question_degree_api";

const QuestionDegreeIndex = () => {
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const showAddModal = () => {
        setIsVisibleAddModal(true);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const response = await questionDegreeIndex();
            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload]);

    return (
        <>
            <QuestionDegreeAddModal setIsVisibleAddModal={setIsVisibleAddModal} isVisibleAddModal={isVisibleAddModal} setReload={setReload} reload={reload} />
            <Row className={'d-flex justify-content-between'}>
                <h5>Permissions</h5>
                <Button type={'success'} onClick={showAddModal}> Add</Button>
            </Row>
            <Row>
                <Col xl={24}>
                    <QuestionDegreeTable data={data} isLoading={isLoading} setReload={setReload} reload={reload}/>
                </Col>
            </Row>
        </>
    )
}

export default QuestionDegreeIndex;