import React, { useContext } from "react";
import Cookies from "universal-cookie";
import { message } from "antd";
import API from "../../api";

export const examsIndex = async (params = {}) => {
  try {
    const response = await API({
      url: "/exam",
      method: "GET",
      params: params,
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};

export const examsShow = async (params = {}, elementID) => {
  try {
    const response = await API({
      url: "/exam/" + elementID,
      method: "GET",
      params: params,
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};

export const examsStore = async (params = {}, data = {}) => {
  try {
    const response = await API({
      url: "/exam",
      method: "POST",
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
      data: data,
      params,
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};
export const examsUpdate = async (elementId, data = {}, params = {}) => {
  try {
    const response = await API({
      url: "/exam/" + elementId,
      method: "PUT",
      data: data,
      params,
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};
export const examsDelete = async (elementId, params) => {
  try {
    const response = await API({
      url: "/exam/" + elementId,
      method: "DELETE",
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
      params,
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};

export const examsGetLessonsChaptersTopics = async (params = {}) => {
  try {
    const response = await API({
      // url: '/exam-get-lessons-chapters-topics',
      url: "/get-lesson-with-count-questions",
      method: "GET",
      params: params,
      headers: {
        Accept: "application/json",
      },
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};

export const examsAttemptsResult = async (examId, params = {}) => {
  try {
    const response = await API({
      url: "/exam-attempts-result/" + examId,
      method: "GET",
      params: params,
      headers: {
        Accept: "application/json",
      },
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};
