import React, { useState } from "react";
import { Modal, Form, Input, message, Spin } from "antd";
import { studentsUpdate } from "../../../../services/apiServices/students_api";

const StudentEditModal = (props) => {
  const {
    setIsEditModalVisible,
    isEditModalVisible,
    selectedElement,
    form,
    setReload,
    reload,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const handleOkEditModal = () => {
    form.submit();
    setIsEditModalVisible(false);
  };

  const handleCancelEditModal = () => {
    form.resetFields();
    setIsEditModalVisible(false);
  };
  const onFinish = (values) => {
    let elementId = form.getFieldValue("id");
    (async () => {
      setIsLoading(true);
      const response = await studentsUpdate(elementId, values);
      if (response) {
        message.success("Ma`lumot saqlandi");
        setReload(!reload);
      }
      setIsLoading(false);
    })();
  };
  const onFinishFailed = (err) => {};
  return (
    <>
      <Modal
        title="O`quvchi ma`lumotini o`zgartirish"
        open={isEditModalVisible}
        onOk={handleOkEditModal}
        onCancel={handleCancelEditModal}
        okText={"Saqlash"}
        cancelText={"Bekor qilish"}
      >
        <Spin tip="Iltimos kuting" spinning={isLoading}>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="FIO"
              name="fio"
              rules={[{ required: true, message: "Please input role name!" }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export default StudentEditModal;
