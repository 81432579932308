import React from "react";
import {Row,Col} from "antd"
import "./style.css"
import {
    UserOutlined,
    UnorderedListOutlined,
    CheckCircleOutlined
}
from "@ant-design/icons"
import {NavLink} from "react-router-dom"
const FooterStudentLayout = () =>{
    return (
        <footer >
            <div className={'p-3 text-center  w-100 d-flex footer-box'} >
                <NavLink  className={'text-center footer-div'}  to={'/student/profile'}>
                   <UserOutlined />
                </NavLink>
                <NavLink to={'/student/exams'}  className={'text-center footer-div'}><UnorderedListOutlined /></NavLink>
                <NavLink  to={'/student/results'} className={'text-center footer-div'}><CheckCircleOutlined /></NavLink>
                {/*<div  className={'text-center '}><UserOutlined /></div>*/}
            </div>
        </footer>
    )
}

export default FooterStudentLayout