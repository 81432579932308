import React from 'react'
import {Table, Popconfirm,message} from "antd"
import {AiOutlineDelete, AiOutlineEdit, AiFillEye} from "react-icons/ai";
import {rolesDelete} from "../../services/apiServices/roles_api";
import {NavLink} from "react-router-dom";

const RolesTable = (props) => {
    const {data, isLoading,setReload,reload} = props;
    let columns = [
        {
            title: 'Name',
            render:(index,element) => <> <NavLink to={{
                pathname: `/roles/${element?.id}`,
                state:{
                    roleName:element.name
                }
            }}>{element?.name}</NavLink> </>
        },
        {
            title: 'Guard name',
            dataIndex: 'guard_name',
            key: 'guard_name',
        },
        {
            title: 'Action',
            render: (index, element) => <div className="d-flex action-box">
                <Popconfirm placement="left" title={'Ma`lumotni o`chirasizmi'} onConfirm={()=>deleteRole(element?.id)} okText="Ha" cancelText="Yo`q">
                    <span className={'text-danger'}><AiOutlineDelete/></span>
                </Popconfirm>
                <span className={'text-warning'}><AiOutlineEdit/></span>
                <span className={'text-primary'}><AiFillEye/></span>
            </div>
        }
    ];
    const deleteRole = (elementId) => {
        if (elementId){
            (async () => {
                const response = await rolesDelete(elementId);
                if (response){
                    message.success('Ma`lumot o`chirildi');
                    setReload(!reload);
                }
            })()
        }
    }
    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} loading={isLoading}/>
        </>
    )
}

export default RolesTable;