import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Pagination, Input} from "antd";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {
    PlusOutlined,
    ArrowLeftOutlined
} from "@ant-design/icons"
import ChapterAddModal from "../../Chapter/crud/Modals/ChapterAddModal";
import ChaptersTable from "../../Chapter/crud/ChaptersTable";
import {chaptersShow} from "../../../services/apiServices/chapters_api";
import TopicAddModal from "../../Topic/crud/Modals/TopicAddModal";
import TopicsTable from "../../Topic/crud/TopicsTable";
import {topicsIndex} from "../../../services/apiServices/topics_api";

const ChaptersShow = () => {
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState();
    const [showCount, setShowCount] = useState(10);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [lesson, setLesson] = useState();
    const navigate = useNavigate();
    const location = useParams();
    const showAddModal = () => {
        setIsVisibleAddModal(true);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let orderBy = {
                field: 'name',
                value: 'ASC'
            }
            let filters = [
                {
                    fieldKey:'chapter_id',
                    fieldValue:location?.id
                }
            ]
            let additional_relations_count = ['questions'];
            let params = {
                show_count: showCount,
                page: page,
                search: search,
                orderBy: JSON.stringify(orderBy),
                filters:JSON.stringify(filters),
                additional_relations_count:JSON.stringify(additional_relations_count)
            }
            const response = await topicsIndex(params);
            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload]);

    const changePage = (page, pageSize) => {
        setPage(page);
        setReload(!reload);
    }

    const changeWord = (e) => {
        setPage(1);
        setSearch(e?.target?.value);
        setReload(!reload);
    }

    return (
        <>
            <TopicAddModal setIsVisibleAddModal={setIsVisibleAddModal} isVisibleAddModal={isVisibleAddModal}
                             setReload={setReload} reload={reload} chapterId={location?.id}/>
            <Row className={'d-flex justify-content-between'}>
                <div className={'d-flex'}>
                     <span onClick={() => navigate(-1)}>
                         <ArrowLeftOutlined className={'go-back-button'}/>
                     </span>
                    <h5>Mavzular ({data?.lesson?.name}/{data?.chapter?.name})</h5>
                </div>
                <div className={'d-flex'}>
                    <Button type={'success'} onClick={showAddModal}> Yangi qo'shish <PlusOutlined
                        className={'text-success'}/></Button>
                </div>
            </Row>
            <Row className={'d-flex justify-content-end mt-2'}>
                <Col xl={4}>
                    <Input allowClear={true} onChange={changeWord}/>
                </Col>
            </Row>
            <Row>

                <Col xl={24}>
                    <TopicsTable data={data} isLoading={isLoading} setReload={setReload} reload={reload}/>
                </Col>
                <Col xl={24} className={'d-flex justify-content-end mt-2'}>
                    <Pagination defaultCurrent={data?.current_page} current={data?.current_page} total={data?.total}
                                pageSize={showCount} onChange={changePage}/>

                </Col>
            </Row>
        </>
    )
}

export default ChaptersShow;