import React, { useEffect, useState } from "react";
import { examsIndex } from "../../../../services/apiServices/exam_api";
import { NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Button, Input, Pagination, Spin } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import ExamTableStudent from "./ExamTableStudent";
import ExamCards from "./ExamCards";

const ExamIndexStudent = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState();
  const [showCount, setShowCount] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let params = {
        show_count: "all",
        orderBy: {
          field: "id",
          value: "DESC",
        },
      };
      const response = await examsIndex(params);
      if (response) {
        setData(response?.result);
      }
      setIsLoading(false);
    })();
  }, [reload]);
  const changePage = (page, pageSize) => {
    setPage(page);
    setReload(!reload);
  };

  const changeWord = (e) => {
    setPage(1);
    setSearch(e?.target?.value);
    setReload(!reload);
  };
  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <h5>Mavjud imtihonlar</h5>
        <div className={"d-flex"}></div>
      </Row>
      <Spin spinning={isLoading}>
        <Row>
          <ExamCards
            data={data?.data}
            isLoading={isLoading}
            setReload={setReload}
            reload={reload}
          />
        </Row>
      </Spin>
    </>
  );
};

export default ExamIndexStudent;
