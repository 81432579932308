import React,{useEffect,useState} from 'react'
import {Row,Col,Button} from "antd";
import PermissionsTable from "./PermissionsTable";
import PermissionAddModal from "./Modals/PermissionAddModal";
import {permissionsIndex} from "../../services/apiServices/permissions_api";

const PermissionsIndex = () => {
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const showAddModal = () => {
        setIsVisibleAddModal(true);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const response = await permissionsIndex();
            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload]);

    return (
        <>
            <PermissionAddModal setIsVisibleAddModal={setIsVisibleAddModal} isVisibleAddModal={isVisibleAddModal} setReload={setReload} reload={reload} />
            <Row className={'d-flex justify-content-between'}>
                <h5>Permissions</h5>
                <Button type={'success'} onClick={showAddModal}> Add</Button>
            </Row>
            <Row>
                <Col xl={24}>
                    <PermissionsTable data={data} isLoading={isLoading} setReload={setReload} reload={reload}/>
                </Col>
            </Row>
        </>
    )
}

export default PermissionsIndex;