import React, {useState} from 'react'
import {Table, Popconfirm, message, Form, Pagination, Row} from "antd"
import {AiOutlineDelete, AiOutlineEdit, AiFillEye} from "react-icons/ai";
import {studentsDelete} from "../../../services/apiServices/students_api";
import StudentEditModal from "./Modals/StudentEditModal";
import StudentShowModal from "./Modals/StudentShowModal";

const StudentsTable = (props) => {
    const [form] = Form.useForm();
    const {data, isLoading, setReload, reload} = props;
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedElement, setSelectedElement] = useState();
    const [showElement, setShowElement] = useState();
    const [isShowModalVisible, setIsShowModalVisible] = useState(false);
    let columns = [
        {
            title: 'F.I.O',
            dataIndex: 'fio',
            key: 'fio',
        },
        {
            title: 'Unikal raqam',
            dataIndex: 'unique_number',
            key: 'unique_number',
        },
        {
            title: 'Amallar',
            render: (index, element) => <div className="d-flex action-box">
                <Popconfirm placement="left" title={'Ma`lumotni o`chirasizmi'} onConfirm={() => deleteElement(element?.id)}
                            okText="Ha" cancelText="Yo`q">
                    <span className={'text-danger'}><AiOutlineDelete/></span>
                </Popconfirm>
                <span className={'text-warning'} onClick={() => showEditModal(element)}><AiOutlineEdit/></span>
                <span className={'text-primary'} onClick={() => showAdmin(element)}><AiFillEye/></span>
            </div>
        }
    ];
    const deleteElement = (elementId) => {
        if (elementId) {
            (async () => {
                const response = await studentsDelete(elementId);
                if (response) {
                    message.success('Ma`lumot o`chirildi');
                    setReload(!reload);
                }
            })()
        }
    }
    const showEditModal = (element) => {
        form.setFieldsValue(element);
        setIsEditModalVisible(true);
        setSelectedElement(element);
    };
    const showAdmin = (element) =>{
        setShowElement(element);
        setIsShowModalVisible(true);
    }
    return (
        <>
            <StudentEditModal
                setIsEditModalVisible={setIsEditModalVisible}
                isEditModalVisible={isEditModalVisible}
                selectedElement={selectedElement}
                form={form}
                setReload={setReload}
                reload={reload}
            />
            <StudentShowModal
                setIsShowModalVisible={setIsShowModalVisible}
                isShowModalVisible={isShowModalVisible}
                showElement={showElement}
            />
            <Table columns={columns} dataSource={data} pagination={false} loading={isLoading}/>
        </>
    )
}

export default StudentsTable;