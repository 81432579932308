/** @format */

import React, { useState } from "react";
import { Table, Popconfirm, message, Form, Pagination, Row } from "antd";
import { AiOutlineDelete, AiOutlineEdit, AiFillEye } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { questionDelete } from "../../../services/apiServices/question_api";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import MathJaxWrapper from "../../../components/Editor/mathJax";

const QuestionTable = (props) => {
  const [form] = Form.useForm();
  const { data, isLoading, setReload, reload } = props;

  let columns = [
    {
      title: "Nomi",
      render: (index, element) => (
        <NavLink to={`/questions/${element?.id}`}>
          <div style={{ maxHeight: "100px", overflow: "hidden" }}>
            <MathJaxWrapper><div dangerouslySetInnerHTML={{ __html: element.content }} /></MathJaxWrapper>
          </div>
        </NavLink>
      ),
    },
    {
      title: "Amallar",
      render: (index, element) => (
        <div className='d-flex action-box'>
          <Popconfirm
            placement='left'
            title={"Ma`lumotni o`chirasizmi"}
            onConfirm={() => deleteElement(element?.id)}
            okText='Ha'
            cancelText='Yo`q'>
            <span className={"text-danger"}>
              <AiOutlineDelete />
            </span>
          </Popconfirm>
          <Link to={`/update_question/${element?.id}`} className={'text-warning'} ><AiOutlineEdit /></Link>
        </div>
      ),
    },
  ];

  const deleteElement = (elementId) => {
    if (elementId) {
      (async () => {
        const response = await questionDelete(elementId);
        if (response) {
          message.success("Ma`lumot o`chirildi");
          setReload(!reload);
        }
      })();
    }
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />
    </>
  );
};

export default QuestionTable;
