import React, {useEffect, useState} from 'react';
import {Button, Row, Col, Table, message} from "antd";
import {useLocation, Outlet, useParams} from "react-router-dom";
import {rolesShow} from "../../services/apiServices/roles_api";
import {givePermissionToRole} from "../../services/apiServices/role_permission_api";

const RoleShow = (props) => {
    const location = useParams();
    const [role, setRole] = useState();
    const [permissions, setPermissions] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const response = await rolesShow(location?.id);

            if (response) {
                setRole(response?.result?.role);
                setPermissions(response?.result?.all_permissions);
                let a = [];
                response?.result?.role?.permissions.map((element, i) => {
                    a.push(element?.id);
                })
                setSelectedPermissions(a);
            }
            setIsLoading(false)
        })()
    }, [reload])
    let columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        }
    ]
    const rowSelection = {
        selectedRowKeys: selectedPermissions,
        onChange: (selectedRowKeys) => {
            setSelectedPermissions(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    const savePermissions = () => {
        (async () => {
            const response = await givePermissionToRole(location?.id,selectedPermissions);
                message.success('Success')
            setReload(!reload)
        })()
    }
    return (
        <>
            <Row className={'d-flex justify-content-between'}>
                <h5>{role?.name}</h5>
                <Button className={' btn-outline-success'} onClick={savePermissions}> Save</Button>
            </Row>
            <Row>
                <Col xl={24}>
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={permissions}
                        rowKey={'id'}
                        rowSelection={{
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        loading={isLoading}
                    />
                </Col>
            </Row>
        </>
    )
}

export default RoleShow;