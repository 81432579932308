import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {generalApi} from "../apiServices/general_api";

export const exportToExcel = (url, fileName,params = {}) => {
    (async () => {
        const res = await generalApi(params, url);
        const apiData = res?.result?.data;
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(apiData);
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    })()
}

export const exportExamAttemptsToExcel = (url,fileName,params = {}) => {
    (async () => {
        const res = await generalApi(params, url);
        const apiData = res?.result?.data;
        let array = [];
        res?.result?.data?.map((element) => {
            array.push({
                'F.I.O':element?.student?.fio,
                'Unikal raqam':element?.student?.unique_number,
                'To`g`ri javoblar soni':element?.exam_student_attempt?.correct_answers_count,

            })
        })
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const ws = XLSX.utils.json_to_sheet(array);
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    })()
}


