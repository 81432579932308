import React, {useContext} from 'react'
import Cookies from 'universal-cookie';
import {message} from "antd";
import API from "../../api";

export const adminsIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/admin',
            method: 'GET',
            params:params
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const adminsStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/admin',
            method: 'POST',
            data: data,
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const adminsUpdate = async (elementId, data = {},params={}) => {
    try {
        const response = await API({
            url: '/admin/' + elementId,
            method: 'PUT',
            data: data,
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const adminsDelete = async (elementId) => {
    try {
        const cookies = new Cookies();
        const response = await API({
            url: '/admin/' + elementId,
            method: 'DELETE',
            headers: {
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}

export const getOrganizations = async (params={}) => {
    try {
        const cookies = new Cookies();
        const response = await API({
            url: '/organizations-for-create-admin',
            method: 'GET',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}
export const showPassword = async (userId,params={}) => {
    try {
        const cookies = new Cookies();
        const response = await API({
            url: '/admin-show-password/'+userId,
            method: 'GET',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}
export const showPasswordStd = async (userId,params={}) => {
    try {
        const cookies = new Cookies();
        const response = await API({
            url: '/student-show-password/'+userId,
            method: 'GET',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}