import React, {useContext} from 'react'
import API from "../../api";
import Cookies from 'universal-cookie';
import {message} from "antd";

export const rolesIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/role',
            method: 'GET',
             headers:{
                // 'Authorization':'Bearer '+cookies.get('auth_token'),
                'Accept':'application/json'
            },
            params
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const rolesShow = async (elementId,params={}) => {
    try {
        const response = await API({
            url: `/role/${elementId}`,
            method: 'GET',
            params,
             headers:{
                // 'Authorization':'Bearer '+cookies.get('auth_token'),
                'Accept':'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const rolesStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/role',
            method: 'POST',
            data: data,
             headers:{
                // 'Authorization':'Bearer '+cookies.get('auth_token'),
                'Accept':'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}
export const rolesDelete = async (elementId,params ={}) => {
    try {
        const cookies = new Cookies();
        const token = cookies.get('auth_token');
        Object.assign(params,{auth_token:token})
        const response = await API({
            url: '/role/'+elementId,
            method: 'DELETE',
             headers:{
                // 'Authorization':'Bearer '+cookies.get('auth_token'),
                'Accept':'application/json'
             },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}