import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  groupsShow,
  groupsStore,
  selectStudentByImport,
  updateGroupsStore,
} from "../../../services/apiServices/group_api";
import { Button, Col, Form, Input, Row, Upload, message } from "antd";
import { ArrowLeftOutlined, UploadOutlined } from "@ant-design/icons";
import SelectedStudentsTable from "./AddTables/SelectedStudentsTable";
import AllStudentsTable from "./AddTables/AllStudentsTable";

const GroupEdit = () => {
  const [formAdd] = Form.useForm();
  const [formUpload] = Form.useForm();
  const [allStudents, setAllStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isLoadingImport, setIsLoadingImport] = useState(false);

  const location = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      const res = await groupsShow({}, location?.id);
      if (res) {
        formAdd.setFieldsValue({
          name: res?.result?.name,
        });
        setSelectedStudents(res.result.students);
        let ids = res?.result?.students?.map((elem, _) => {
          return elem?.student_unique_number;
        });
        setSelectedKeys(ids);
      }
    })();
  }, [location.id, formAdd]);

  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
      }
      if (info.file.status === "done") {
      } else if (info.file.status === "error") {
      }
    },
  };
  const selectByImport = () => {
    formUpload.submit();
  };
  const onFinishUpload = (values) => {
    const data = new FormData();
    data.append("file", values?.file?.file?.originFileObj);
    (async () => {
      setIsLoadingImport(true);
      const res = await selectStudentByImport(data);
      let rrr = res?.result?.filter(function (elem) {
        return !selectedKeys.includes(elem?.unique_number);
      });
      let t = [...selectedStudents, ...rrr];
      setSelectedStudents(t);
      let ids = t.map((elem, _) => {
        return elem?.unique_number;
      });
      setSelectedKeys(ids);
      setIsLoadingImport(false);
    })();
  };
  const saveGroup = () => {
    formAdd.submit();
  };
  const onFinish = (values) => {
    if (selectedKeys?.length > 0) {
      (async () => {
        const data = {
          name: values.name,
          students: selectedKeys,
        };
        const res = await updateGroupsStore(location?.id, data);
        if (res) {
          message.success("Guruh yaratildi");
          navigate("/groups");
        }
      })();
    } else {
      message.error("O`quvchi tanlanmagan");
    }
  };
  return (
    <div>
      <Row className={"d-flex justify-content-between"}>
        <div className={"d-flex"}>
          <span onClick={() => navigate(-1)}>
            <ArrowLeftOutlined className={"go-back-button"} />
          </span>
          <h5>Guruh o'zgartirish</h5>
        </div>
      </Row>
      <Form
        form={formAdd}
        name="basic"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 23,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <Col xl={6}>
            <Form.Item
              label="Guruh nomi"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Guruh nomini kiriting!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={6}></Col>
          <Col xl={6}></Col>
          <Col xl={2} className={"d-flex justify-content-end"}>
            <Button className={"btn-outline-success"} onClick={saveGroup}>
              Saqlash
            </Button>
          </Col>
        </Row>
      </Form>
      <Form
        form={formUpload}
        name="import"
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 23,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinishUpload}
        autoComplete="off"
      >
        <Row>
          <Col xl={12}>
            <span>
              <b>O'quvchilarni tanlash</b>
            </span>
          </Col>
          <Col xl={12}>
            <span>
              <b>Tanlanganlar</b>
            </span>
          </Col>
          <Col xl={12} className={"p-1 border"}>
            <AllStudentsTable
              allStudents={allStudents}
              setAllStudents={setAllStudents}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
            />
          </Col>

          <Col xl={12} className={"p-1 border"}>
            <div className={"d-flex"}>
              <Form.Item label="" name="file">
                <Upload
                  {...props}
                  accept={".xls,.xlsx"}
                  customRequest={({ onSuccess }) => {
                    onSuccess("ok");
                  }}
                >
                  <Button loading={isLoadingImport} icon={<UploadOutlined />}>
                    Excel file dan unikal raqamlar orqali import qilish{" "}
                    <span className={"text-danger"}>Maksimal soni : 50!</span>
                  </Button>
                </Upload>
              </Form.Item>
              <Button
                onClick={selectByImport}
                loading={isLoadingImport}
                disabled={isLoadingImport}
              >
                Import
              </Button>
            </div>
            <SelectedStudentsTable
              allStudents={allStudents}
              setAllStudents={setAllStudents}
              isLoadingImport={isLoadingImport}
              selectedStudents={selectedStudents}
              setSelectedStudents={setSelectedStudents}
              setSelectedKeys={setSelectedKeys}
              selectedKeys={selectedKeys}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default GroupEdit;
