import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Pagination, Input,Table} from "antd";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {
    FileExcelOutlined,
    PlusOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined,
    ArrowLeftOutlined
} from "@ant-design/icons"
import {groupsShow} from "../../../services/apiServices/group_api";
const GroupShow = () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const location = useParams();
    useEffect(() => {
        (async () => {
            const res = await groupsShow({},location?.id);
            if (res){
                setData(res?.result);
            }
        })()
    }, []);
    let columns = [
        {
            title:'F.I.O',
            render:(index,element) => <>{element?.student?.fio}</>
        },
        {
            title:'Unikal raqam',
            render:(index,element) => <>{element?.student?.unique_number}</>
        },
    ]
    return (
        <>
            <Row className={'d-flex justify-content-between'}>
                <div className={'d-flex'}>
                 <span onClick={() => navigate(-1)}>
                     <ArrowLeftOutlined className={'go-back-button'}/>
                 </span>
                    <h4> <b>{data?.name}</b></h4>
                </div>
            </Row>
            <Row>
                <h6>O'quvchilar</h6>
                <Col xl={24}>

                <Table dataSource={data?.students} columns={columns} pagination={false} />
                </Col>
            </Row>

        </>
    )
}

export default GroupShow;