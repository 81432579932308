import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Select } from "antd";
import { message } from "antd";
import {
  adminsStore,
  getOrganizations,
} from "../../../../services/apiServices/admins_api";

const AdminAddModal = (props) => {
  const { Option } = Select;
  const { setIsVisibleAddModal, isVisibleAddModal, setReload, reload } = props;
  const [organizations, setOrganizations] = useState([]);
  const [form] = Form.useForm();
  const handleOkAdd = () => {
    form.submit();
  };
  const handleCancelAdd = () => {
    setIsVisibleAddModal(false);
  };
  const onFinish = (values) => {
    const data = new FormData();
    for (const key in values) {
      data.append(key, values[key]);
    }
    (async () => {
      const response = await adminsStore({}, data);

      if (response) {
        message.success("Ma`lumot saqlandi");
        form.resetFields();
        setIsVisibleAddModal(false);
        setReload(!reload);
      }
    })();
  };
  useEffect(() => {
    (async () => {
      const res = await getOrganizations();
      if (res) {
        setOrganizations(res?.result);
      }
    })();
  }, [reload]);
  const onFinishFailed = (error) => {};
  return (
    <>
      <Modal
        title="Add admin"
        open={isVisibleAddModal}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        okText={"save"}
        cancelText={"cancel"}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="FIO"
            name="fio"
            rules={[{ required: true, message: "Please input admin fio!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Organization"
            name="organization_id"
            rules={[{ required: true, message: "Please select organization!" }]}
          >
            <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {organizations &&
                organizations?.map((element, index) => {
                  return (
                    <Option value={element?.id} key={index}>
                      {element?.name}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AdminAddModal;
