import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { questionShow } from "../../../services/apiServices/question_api";
import MathJaxWrapper from "../../../components/Editor/mathJax";

const QuestionShow = () => {
  const location = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  useEffect(() => {
    (async () => {
      const response = await questionShow({}, location?.id);
      if (response) {
        setData(response?.result);
      }
    })();
  }, []);
  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <div className={"d-flex"}>
          <span onClick={() => navigate(-1)}>
            <ArrowLeftOutlined className={"go-back-button"} />
          </span>
          <h5>Savolni ko'rish</h5>
        </div>
        <div className={"d-flex"}></div>
      </Row>
      <Row>
        <Col xl={24} className={"p-1"}>
          <p>
            <b>Savol matni </b>
          </p>
          <p>
            <MathJaxWrapper>
              <div
                dangerouslySetInnerHTML={{ __html: data?.question?.content }}
              />
            </MathJaxWrapper>
          </p>
        </Col>
      </Row>
      <Row>
        <p>
          <b>Javoblar </b>
        </p>
        {data?.answers &&
          data?.answers.map((element, index) => {
            return (
              <Col
                xl={24}
                className={
                  element.is_correct == 1
                    ? "border-success text-success p-1 border m-2"
                    : "border-danger text-danger p-1 border m-2"
                }
              >
                <p>
                  <b>{index + 1}-Javob</b>
                  <MathJaxWrapper>
                    <div
                      dangerouslySetInnerHTML={{ __html: element?.content }}
                    />
                  </MathJaxWrapper>
                </p>
              </Col>
            );
          })}
      </Row>
    </>
  );
};

export default QuestionShow;
