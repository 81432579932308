import React from "react";
import {Row, Col} from "antd"
import "./stylePrint.css"

export const PrintPasswordsComponent = React.forwardRef((props, ref) => {
    const {attempts, exam} = props;
    return (
        <div ref={ref} className={'print-box m-1'}>
            {
                attempts?.length > 0 && attempts?.map((element, index) => {
                    return (
                        <div  className={'p-2 border print-box-item  '}>
                            <div>
                                <b>
                                    Imtihon nomi :
                                </b>
                                <span>{exam?.name}</span>
                            </div>
                            <div>

                            </div>
                            <div>
                                <b>
                                    F.I.O:
                                </b>
                                <span>
                                     {element?.student?.fio}
                                </span>
                            </div>
                            <div>
                                <b>Unikal raqam:</b>
                                <span>  {element?.student?.unique_number}</span>
                            </div>
                            <div>
                                <b>
                                    Imtihon paroli:
                                </b>
                                <span> {element?.password}</span>
                            </div>
                        </div>

                    )
                })
            }
        </div>
    );
});