import React, {useContext} from 'react'
import API from "../../api";
import Cookies from 'universal-cookie';
import {message} from "antd";

export const givePermissionToRole = async (roleId, selectedPermissionsIds,params={}) => {
    try {
        const response = await API({
            url: '/give-permission/' + roleId,
            method: 'PUT',
            data: {
                permissions: selectedPermissionsIds
            },
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
