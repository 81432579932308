// export const  PATH_PREFIX = 'http://127.0.0.1:1234/api'
// export const  PATH_PREFIX_FILE = 'http://127.0.0.1:1234'
// export const  CONTENT_IMAGE_UPLOAD_PATH = PATH_PREFIX+'/upload-image'

// export const  PATH_PREFIX_FILE = 'http://127.0.0.1:8000'
// export const  PATH_PREFIX = PATH_PREFIX_FILE+'/api'
// export const  CONTENT_IMAGE_UPLOAD_PATH = PATH_PREFIX+'/upload-image'

// export const  PATH_PREFIX = 'http://10.1.1.232:8000/api'
// export const  PATH_PREFIX_FILE = 'http://10.1.1.232:8000'
// export const  CONTENT_IMAGE_UPLOAD_PATH = PATH_PREFIX+'/upload-image'

export const PATH_PREFIX = "http://smrted-api.intalim.uz/api";
export const PATH_PREFIX_FILE = "http://smrted-api.intalim.uz";
export const CONTENT_IMAGE_UPLOAD_PATH = PATH_PREFIX + "/upload-image";
