import React from 'react'
import {Table, Popconfirm,message} from "antd"
import {AiOutlineDelete, AiOutlineEdit, AiFillEye} from "react-icons/ai";
import {questionDegreeDelete} from "../../services/apiServices/question_degree_api";

const QuestionDegreeTable = (props) => {
    const {data, isLoading,setReload,reload} = props;
    let columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Guard name',
            dataIndex: 'guard_name',
            key: 'guard_name',
        },
        {
            title: 'Action',
            render: (index, element) => <div className="d-flex action-box">
                <Popconfirm placement="left" title={'Ma`lumotni o`chirasizmi'} onConfirm={()=>deleteRole(element?.id)} okText="Ha" cancelText="Yo`q">
                    <span className={'text-danger'}><AiOutlineDelete/></span>
                </Popconfirm>
                <span className={'text-warning'}><AiOutlineEdit/></span>
                <span className={'text-primary'}><AiFillEye/></span>
            </div>
        }
    ];
    const deleteRole = (elementId) => {
        if (elementId){
            (async () => {
                const response = await questionDegreeDelete(elementId);
                if (response){
                    message.success('Ma`lumot o`chirildi');
                    setReload(!reload);
                }
            })()
        }
    }
    return (
        <>
            <Table columns={columns} dataSource={data} pagination={true} loading={isLoading} rowKey={'id'}/>
        </>
    )
}

export default QuestionDegreeTable;