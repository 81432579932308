import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Pagination, Input} from "antd";
import {studentsIndex} from "../../../services/apiServices/students_api";
import StudentAddModal from "./Modals/StudentAddModal";
import StudentsTable from "./StudentsTable";
import {NavLink} from "react-router-dom";
import {
    FileExcelOutlined,
    PlusOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined
} from "@ant-design/icons"
import {exportToExcel} from "../../../services/export/exportFunction";

const StudentsIndex = () => {
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState();
    const [showCount, setShowCount] = useState(10);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const showAddModal = () => {
        setIsVisibleAddModal(true);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let orderBy = {
                field: 'id',
                value: 'DESC'
            }
            let params = {
                show_count: showCount,
                page: page,
                search: search,
                orderBy: JSON.stringify(orderBy)
            }
            const response = await studentsIndex(params);
            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload]);

    const changePage = (page, pageSize) => {
        setPage(page);
        setReload(!reload);
    }

    const changeWord = (e) => {
        setPage(1);
        setSearch(e?.target?.value);
        setReload(!reload);
    }
    const exportExcel = () => {
        let select = ['fio as Familiya ismi sharifi', 'unique_number as Unikal raqam'];
        let params = {
            select: JSON.stringify(select)
        }
        exportToExcel('/student', 'students', params);
    }

    return (
        <>
            <StudentAddModal setIsVisibleAddModal={setIsVisibleAddModal} isVisibleAddModal={isVisibleAddModal}
                             setReload={setReload} reload={reload}/>
            <Row className={'d-flex justify-content-between'}>
                <h5>O'quvchilar</h5>
                <div className={'d-flex'}>
                    <Button className={'btn-outline-success'} onClick={showAddModal} icon={<PlusOutlined/>}> Yangi
                        qo'shish </Button>
                    <NavLink to={'/import-students'}><Button type={'success'} icon={<ArrowDownOutlined/>}> Excel dan
                        import qilish <FileExcelOutlined className={'text-success'}/> </Button></NavLink>
                    <Button type={'success'} onClick={exportExcel} icon={<ArrowUpOutlined/>}> Excelga export
                        qilish <FileExcelOutlined className={'text-success'}/> </Button>
                </div>
            </Row>
            <Row className={'d-flex justify-content-end mt-2'}>
                <Col xl={4}>
                    <Input allowClear={true} onChange={changeWord}/>
                </Col>
            </Row>
            <Row>

                <Col xl={24}>
                    <StudentsTable data={data?.data} isLoading={isLoading} setReload={setReload} reload={reload}/>
                </Col>
                <Col xl={24} className={'d-flex justify-content-end mt-2'}>
                    <Pagination defaultCurrent={data?.current_page} current={data?.current_page} total={data?.total}
                                pageSize={showCount} onChange={changePage}/>

                </Col>
            </Row>
        </>
    )
}

export default StudentsIndex;