import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Form,
  Select,
  TimePicker,
  DatePicker,
  Checkbox,
  Collapse,
  InputNumber,
  Spin,
  message,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  CaretRightOutlined,
  CaretDownOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { questionDegreeIndex } from "../../../services/apiServices/question_degree_api";
import { groupsIndex } from "../../../services/apiServices/group_api";

import "./exam.css";
import { examsStore } from "../../../services/apiServices/exam_api";
import { langIndex } from "../../../services/apiServices/lang_api";
import API from "../../../api";

const ExamAdd = () => {
  const { Panel } = Collapse;
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [questionDegrees, setQuestionDegrees] = useState([]);
  const [status, setStatus] = useState("active");
  const [groups, setGroups] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [topics, setTopics] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [lessonBall, setLessonBall] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [langs, setLangs] = useState([]);
  const [questionDegreeIds, setQuestionDegreeIds] = useState();
  const [disableAllInputs, setDisableAllInputs] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await questionDegreeIndex();
      setQuestionDegrees(response?.result);
      const groupsResult = await groupsIndex({ show_count: "all" });
      setGroups(groupsResult?.result?.data);
      setIsLoading(false);
      const langResponse = await langIndex({ show_count: "all" });
      setLangs(langResponse?.result);
    })();
  }, []);
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  function selectGroup(value) {
    console.log(`selected ${value}`);
  }

  const children = [];
  groups?.map((element, index) => {
    children.push(
      <Option key={index} value={element?.id}>
        {element?.name}
      </Option>
    );
  });

  const getLessonsChaptersTopics = () => {
    if (disableAllInputs) {
      setLessons([]);
      setCheckedItems([]);
      setDisableAllInputs(false);
    } else {
      form.validateFields().then(() => {
        (async () => {
          const lessonsResponse = await API.get(
            "/get-lesson-with-count-questions",
            {
              params: {
                lang_id: form.getFieldValue("lang_id"),
              },
            }
          );
          let newItems = lessonsResponse?.data?.result?.data || [];

          newItems.forEach((item) => {
            item.checked = false;
            item.show = false;
          });

          setLessons(newItems);
          setLessonBall(() => {
            let pp = [];
            lessonsResponse?.data?.result?.data?.map((element) => {
              pp.push({
                lessonId: element?.id,
                ball: "1",
              });
            });
            return pp;
          });
          setDisableAllInputs(true);
        })();
      });
    }
  };

  const removeChildrenCheckedItems = (parentId) => {
    setCheckedItems(
      checkedItems?.filter((element) => element?.parentId !== parentId)
    );
  };
  const removeChildrenLessonCheckedItems = (parentId) => {
    setCheckedItems(
      checkedItems?.filter((element) => element?.lessonId !== parentId)
    );
  };

  const checkLesson = (index, element, e, type) => {
    console.log("element", element);
    if (e?.target?.checked) {
      if (type === "lesson") {
        removeChildrenCheckedItems(element?.id);
        removeChildrenLessonCheckedItems(element?.id);
        setCheckedItems((prevState) => [
          ...prevState,
          {
            itemId: element?.id,
            type: type,
            lessonId: element?.id,
            questionCount: [
              {
                question_degree_id: 1,
                elementCount: element?.count_1,
              },
              {
                question_degree_id: 2,
                elementCount: element?.count_2,
              },
              {
                question_degree_id: 3,
                elementCount: element?.count_3,
              },
            ],
          },
        ]);
      }
      if (type === "chapter") {
        removeChildrenCheckedItems(element?.id);
        setCheckedItems((prevState) => [
          ...prevState,
          {
            itemId: element?.id,
            type: type,
            lessonId: element.lesson_id,
            parentId: element?.lesson_id,
            questionCount: [
              {
                question_degree_id: 1,
                elementCount: element?.count_1,
              },
              {
                question_degree_id: 2,
                elementCount: element?.count_2,
              },
              {
                question_degree_id: 3,
                elementCount: element?.count_3,
              },
            ],
          },
        ]);
      }
      if (type === "topic") {
        setCheckedItems((prevState) => [
          ...prevState,
          {
            itemId: element?.id,
            type: type,
            lessonId: element.lesson_id,
            parentId: element?.lesson_id,
            questionCount: [
              {
                question_degree_id: 1,
                elementCount: element?.count_1,
              },
              {
                question_degree_id: 2,
                elementCount: element?.count_2,
              },
              {
                question_degree_id: 3,
                elementCount: element?.count_3,
              },
            ],
          },
        ]);
      }
    } else {
      setCheckedItems(
        checkedItems.filter(
          (elementLesson) =>
            !(
              elementLesson?.itemId === element?.id &&
              elementLesson?.type === type
            )
        )
      );
    }
    if (type === "lesson") {
      let yyy = lessons?.map((elementLesson, indexL) => {
        if (indexL === index) {
          return {
            ...elementLesson,
            checked: e?.target?.checked,
          };
        } else {
          return elementLesson;
        }
      });

      setLessons(yyy);
    }
    if (type === "chapter") {
      let ch = chapters?.map((elementChapter) => {
        if (elementChapter?.id === element?.id) {
          return {
            ...elementChapter,
            checked: e?.target?.checked,
          };
        } else {
          return elementChapter;
        }
      });

      setChapters(ch);
    }
    if (type === "topic") {
      let tt = topics?.map((elementTopic) => {
        if (elementTopic?.id === element?.id) {
          return {
            ...elementTopic,
            checked: e?.target?.checked,
          };
        } else {
          return elementTopic;
        }
      });

      setTopics(tt);
    }
  };

  const selectQuestionDegreeId = (val) => {
    setQuestionDegreeIds(val);
  };

  const onChangeInput = (e, elementId, type, question_degree_id) => {
    const index = checkedItems.findIndex(
      (elem) =>
        elem.type === type &&
        elem.itemId === elementId &&
        elem.questionCount.some(
          (question) => question.question_degree_id === question_degree_id
        )
    );
    if (index !== -1) {
      const updatedCheckedItems = [...checkedItems];
      const questionIndex = updatedCheckedItems[index].questionCount.findIndex(
        (question) => question.question_degree_id === question_degree_id
      );

      if (questionIndex !== -1) {
        updatedCheckedItems[index].questionCount[questionIndex].elementCount =
          parseInt(e, 10) || 0;
        setCheckedItems(updatedCheckedItems);
      }
    }
  };

  const showItem = async (element, type) => {
    var change = null;
    if (!element?.show) {
      change = true;
    } else {
      change = false;
    }
    if (type === "lesson") {
      if (change) {
        const chaptersResponse = await API.get(
          "/get-lesson-with-count-questions",
          {
            params: {
              lesson_id: element?.id,
              lang_id: form.getFieldValue("lang_id"),
            },
          }
        );
        let newItems = chaptersResponse?.data?.result?.data || [];

        newItems.forEach((item) => {
          item.checked = false;
          item.show = false;
        });

        setChapters(newItems);
      }
      setLessons(
        lessons?.map((elementLesson) => {
          if (elementLesson?.id === element?.id) {
            return { ...elementLesson, show: change };
          } else {
            return elementLesson;
          }
        })
      );
    }
    if (type === "chapter") {
      if (change) {
        const topicsResponse = await API.get(
          "/get-lesson-with-count-questions",
          {
            params: {
              chapter_id: element?.id,
              lang_id: form.getFieldValue("lang_id"),
            },
          }
        );
        let newItems = topicsResponse?.data?.result?.data || [];

        newItems.forEach((item) => {
          item.checked = false;
        });

        setTopics(newItems);
      }
      setChapters(
        chapters?.map((elementChapter) => {
          if (elementChapter?.id === element?.id) {
            return { ...elementChapter, show: change };
          } else {
            return elementChapter;
          }
        })
      );
    }
  };

  const summaryQuestions = () => {
    let totalSum = 0;
    checkedItems.forEach((item) => {
      item.questionCount.forEach((question) => {
        totalSum += question.elementCount;
      });
    });
    return totalSum;
  };

  function showLessonBall(lessonId) {
    let questionCount = checkedItems
      .filter((elementF) => elementF?.lessonId === lessonId)
      .reduce((sum, item) => {
        return (
          sum +
          item.questionCount.reduce((questionSum, question) => {
            return questionSum + question.elementCount;
          }, 0)
        );
      }, 0);
    let ball = lessonBall.find((findElement) => {
      return findElement?.lessonId === lessonId;
    })?.ball;
    return questionCount * ball;
  }

  function showAllBall() {
    let ball = 0;
    checkedItems?.map((element) => {
      let yy = lessonBall?.find((elementF) => {
        return elementF?.lessonId === element?.lessonId;
      })?.ball;
      ball =
        ball +
        element.questionCount?.reduce((questionSum, question) => {
          return questionSum + question.elementCount;
        }, 0) *
          yy;
    });
    return ball;
  }

  const collapseHeader =
    "Umumiy tanlangan savollar soni: " +
    summaryQuestions() +
    " Umumiy ball :" +
    showAllBall();

  const changeLessonExamBall = (lessonId, e) => {
    setLessonBall(
      lessonBall?.map((element) => {
        if (element?.lessonId === lessonId) {
          return { ...element, ball: e?.target?.value };
        } else {
          return element;
        }
      })
    );
  };

  const onFinish = (values) => {
    const filteredLessonBall = lessonBall.filter((item) =>
      checkedItems.some((checkedItem) => checkedItem.lessonId === item.lessonId)
    );
    const data = new FormData();
    for (const key in values) {
      data.append(key, values[key]);
    }

    data.append("question_info", JSON.stringify(checkedItems));
    data.append("ball_info", JSON.stringify(filteredLessonBall));

    (async () => {
      setIsLoading(true);
      const response = await examsStore({}, data);
      if (response?.status === 1) {
        message.success("Ma`lumot saqlandi");
        navigate("/exams");
      }
      setIsLoading(false);
    })();
  };

  const submitData = () => {
    form.submit();
  };

  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <div className={"d-flex"}>
          <span onClick={() => navigate(-1)}>
            <ArrowLeftOutlined className={"go-back-button"} />
          </span>
          <h5>Imtihon qo'shish</h5>
        </div>
        <div className={"d-flex"}></div>
      </Row>

      <Spin spinning={isLoading} tip={"Iltimos kuting"}>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 23,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Row>
            <Col xl={6}>
              <Form.Item
                label="Imtihon nomi"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Imtihon nomini kiriting!",
                  },
                ]}
              >
                <Input disabled={disableAllInputs} />
              </Form.Item>
            </Col>

            <Col xl={6}>
              <Form.Item
                label="Urinishlar soni"
                name="attempts_count"
                rules={[
                  {
                    required: true,
                    message: "Urinishlar sonini kiriting!",
                  },
                ]}
              >
                <Input disabled={disableAllInputs} />
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label="Imtihon vaqti davomiyligi"
                name="duration"
                rules={[
                  {
                    required: true,
                    message: "Davomiylikni kiriting!",
                  },
                ]}
                initialValue={moment("00:30:00", "HH:mm:ss")}
              >
                <TimePicker
                  style={{ width: "100%" }}
                  disabled={disableAllInputs}
                />
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label="Savollar qiyinlik darajasi"
                name="question_degree_id"
                rules={[
                  {
                    required: true,
                    message: "Savollar qiyinlik darajasini tanlang!",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="Tanlang"
                  optionFilterProp="children"
                  onChange={selectQuestionDegreeId}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={disableAllInputs}
                >
                  {questionDegrees &&
                    questionDegrees?.map((element, index) => {
                      return (
                        <Option key={index} value={element?.id}>
                          {element?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={12}>
              <Form.Item
                label="Guruhlar"
                name="groups"
                rules={[
                  {
                    required: true,
                    message: "Guruhlarni tanlang!",
                  },
                ]}
              >
                <Select
                  disabled={disableAllInputs}
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Please select"
                  onChange={selectGroup}
                >
                  {children}
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label="Imtihon holati"
                name="status"
                rules={[
                  {
                    required: true,
                    message: "Imtihon holati!",
                  },
                ]}
                initialValue={"active"}
              >
                <Select
                  disabled={disableAllInputs}
                  showSearch
                  placeholder="Tanlang"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  value={status}
                  onChange={(e) => setStatus(e)}
                >
                  <Option value={"active"}>Faol</Option>
                  <Option value={"inactive"}>Faol emas</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label="Imtihon faol bo`lib turuvchi davr"
                name="exam_start_end_time"
                rules={[
                  {
                    required: status == "active" ? true : false,
                    message: "Davomiylikni kiriting!",
                  },
                ]}
              >
                <RangePicker
                  disabled={
                    disableAllInputs ? true : status == "active" ? false : true
                  }
                  renderExtraFooter={() => "extra footer"}
                  showTime
                />
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label="Imtihon himoya holati"
                name="is_protected"
                rules={[
                  {
                    required: true,
                    message: "Himoya holati!",
                  },
                ]}
                initialValue={0}
              >
                <Select
                  disabled={disableAllInputs}
                  showSearch
                  placeholder="Tanlang"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>Himoyalangan (Parol bilan kiriladi)</Option>
                  <Option value={0}>Ochiq (Parolsiz kiriladi)</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label="Imtihon testlari yechilganidan keyin imtihon natijasida togri javoblar korsatilsinmi ?"
                name="show_correct_answers"
                rules={[
                  {
                    required: true,
                    message: "Tanlang!",
                  },
                ]}
                initialValue={1}
              >
                <Select
                  disabled={disableAllInputs}
                  showSearch
                  placeholder="Tanlang"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={1}>Ha</Option>
                  <Option value={0}>Yo`q</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xl={6}>
              <Form.Item
                label="Imtihon savollari tili"
                name="lang_id"
                rules={[
                  {
                    required: true,
                    message: "Tili!",
                  },
                ]}
              >
                <Select
                  disabled={disableAllInputs}
                  showSearch
                  placeholder="Tanlang"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {langs &&
                    langs?.map((element, index) => {
                      return (
                        <Option value={element?.id} key={index}>
                          {element?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              className={"w-100"}
              type={"primary"}
              onClick={getLessonsChaptersTopics}
            >
              {disableAllInputs
                ? "Imtihon ma`lumotlarini o`zgartirish"
                : "Fanlarni ochish"}
            </Button>
          </Row>

          <Row>
            <Col xl={24} className="p-1">
              <b>Fanlar</b>
              {lessons?.map((element, index) => {
                return (
                  <div
                    className={"w-100  p-2 border row-hover"}
                    key={index}
                    style={{ backgroundColor: "#f5f5f5" }}
                  >
                    <div className={"w-100 d-flex justify-content-between"}>
                      <div className={"d-flex w-100"}>
                        <Checkbox
                          indeterminate={
                            !element?.checked
                              ? checkedItems.filter(
                                  (elemFCH) =>
                                    elemFCH?.type !== "lesson" &&
                                    elemFCH?.lessonId === element?.id
                                ).length > 0
                                ? true
                                : false
                              : false
                          }
                          onChange={(e) =>
                            checkLesson(index, element, e, "lesson")
                          }
                          disabled={
                            element?.show ||
                            element?.count_1 +
                              element?.count_2 +
                              element?.count_3 ===
                              0
                          }
                        >
                          {element?.name}
                        </Checkbox>
                      </div>
                      <div className={"d-flex w-100 justify-content-end"}>
                        {element?.checked && (
                          <InputNumber
                            className={"input-number"}
                            size={"large"}
                            min={0}
                            max={element?.count_1}
                            defaultValue={
                              element?.checked ? element?.count_1 : ""
                            }
                            onChange={(e) =>
                              onChangeInput(e, element?.id, "lesson", 1)
                            }
                          />
                        )}
                        <div
                          className={
                            "justify-content-center align-items-center d-flex border"
                          }
                          style={{ width: "100px" }}
                        >
                          Osson:
                          <b className="ms-1">{element?.count_1 || 0}</b>
                        </div>

                        {element?.checked && (
                          <InputNumber
                            className={"input-number"}
                            size={"large"}
                            min={0}
                            max={element?.count_2}
                            defaultValue={
                              element?.checked ? element?.count_2 : 0
                            }
                            onChange={(e) =>
                              onChangeInput(e, element?.id, "lesson", 2)
                            }
                          />
                        )}
                        <div
                          className={
                            "justify-content-center align-items-center d-flex border"
                          }
                          style={{ width: "100px" }}
                        >
                          O'rtacha:{" "}
                          <b className="ms-1"> {element?.count_2 || 0}</b>
                        </div>

                        {element?.checked && (
                          <InputNumber
                            className={"input-number"}
                            size={"large"}
                            min={0}
                            max={element?.count_3}
                            defaultValue={
                              element?.checked ? element?.count_3 : 0
                            }
                            onChange={(e) =>
                              onChangeInput(e, element?.id, "lesson", 3)
                            }
                          />
                        )}
                        <div
                          className={
                            "justify-content-center align-items-center d-flex border"
                          }
                          style={{ width: "100px" }}
                        >
                          Qiyin:
                          <b className="ms-1">{element?.count_3 || 0}</b>
                        </div>
                        <div
                          className={
                            "justify-content-center align-items-center d-flex border"
                          }
                          style={{ width: "100px" }}
                        >
                          Umumiy:
                          <b className="ms-1">
                            {element?.count_1 +
                              element?.count_2 +
                              element?.count_3 || 0}
                          </b>
                        </div>
                        <Button
                          disabled={element?.checked}
                          onClick={() => showItem(element, "lesson")}
                          icon={
                            element?.show ? (
                              <CaretDownOutlined />
                            ) : (
                              <CaretRightOutlined />
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className={element?.show ? " showed " : "panel"}>
                      <div className={"w-100 d-flex justify-content-between"}>
                        <span>
                          <b>Boblar ({element?.name})</b>
                        </span>
                      </div>
                      {chapters?.map((chapter, chapterIndex) => {
                        return (
                          <div
                            className={"w-100  p-2 border row-hover"}
                            key={chapterIndex}
                            style={{ backgroundColor: "#f5f5f5" }}
                          >
                            <div
                              className={"w-100 d-flex justify-content-between"}
                            >
                              <div className={"d-flex w-100"}>
                                <Checkbox
                                  indeterminate={
                                    !chapter?.checked
                                      ? checkedItems.filter(
                                          (elemFCH) =>
                                            elemFCH?.type !== "chapter" &&
                                            elemFCH?.parentId === chapter?.id
                                        ).length > 0
                                        ? true
                                        : false
                                      : false
                                  }
                                  onChange={(e) =>
                                    checkLesson(
                                      chapterIndex,
                                      chapter,
                                      e,
                                      "chapter"
                                    )
                                  }
                                  disabled={
                                    chapter?.show ||
                                    chapter?.count_1 +
                                      chapter?.count_2 +
                                      chapter?.count_3 ===
                                      0
                                  }
                                >
                                  {chapter?.name}
                                </Checkbox>
                              </div>
                              <div
                                className={"d-flex w-100 justify-content-end"}
                              >
                                {chapter?.checked && (
                                  <InputNumber
                                    className={"input-number"}
                                    size={"large"}
                                    min={0}
                                    max={chapter?.count_1}
                                    defaultValue={
                                      chapter?.checked ? chapter?.count_1 : 0
                                    }
                                    onChange={(e) =>
                                      onChangeInput(
                                        e,
                                        chapter?.id,
                                        "chapter",
                                        1
                                      )
                                    }
                                  />
                                )}
                                <div
                                  className={
                                    "justify-content-center align-items-center d-flex border"
                                  }
                                  style={{ width: "100px" }}
                                >
                                  Osson:
                                  <b className="ms-1">
                                    {chapter?.count_1 || 0}
                                  </b>
                                </div>

                                {chapter?.checked && (
                                  <InputNumber
                                    className={"input-number"}
                                    size={"large"}
                                    min={0}
                                    max={chapter?.count_2}
                                    defaultValue={
                                      chapter?.checked ? chapter?.count_2 : 0
                                    }
                                    onChange={(e) =>
                                      onChangeInput(
                                        e,
                                        chapter?.id,
                                        "chapter",
                                        2
                                      )
                                    }
                                  />
                                )}
                                <div
                                  className={
                                    "justify-content-center align-items-center d-flex border"
                                  }
                                  style={{ width: "100px" }}
                                >
                                  O'rtacha:{" "}
                                  <b className="ms-1">
                                    {" "}
                                    {chapter?.count_2 || 0}
                                  </b>
                                </div>

                                {chapter?.checked && (
                                  <InputNumber
                                    className={"input-number"}
                                    size={"large"}
                                    min={0}
                                    max={chapter?.count_3}
                                    defaultValue={
                                      chapter?.checked ? chapter?.count_3 : 0
                                    }
                                    onChange={(e) =>
                                      onChangeInput(
                                        e,
                                        chapter?.id,
                                        "chapter",
                                        3
                                      )
                                    }
                                  />
                                )}
                                <div
                                  className={
                                    "justify-content-center align-items-center d-flex border"
                                  }
                                  style={{ width: "100px" }}
                                >
                                  Qiyin:
                                  <b className="ms-1">
                                    {chapter?.count_3 || 0}
                                  </b>
                                </div>
                                <div
                                  className={
                                    "justify-content-center align-items-center d-flex border"
                                  }
                                  style={{ width: "100px" }}
                                >
                                  Umumiy:
                                  <b className="ms-1">
                                    {chapter?.count_1 +
                                      chapter?.count_2 +
                                      chapter?.count_3 || 0}
                                  </b>
                                </div>
                                <Button
                                  disabled={chapter?.checked}
                                  onClick={() => showItem(chapter, "chapter")}
                                  icon={
                                    chapter?.show ? (
                                      <CaretDownOutlined />
                                    ) : (
                                      <CaretRightOutlined />
                                    )
                                  }
                                />
                              </div>
                            </div>

                            <div
                              className={chapter?.show ? " showed " : "panel"}
                            >
                              <div
                                className={
                                  "w-100 d-flex justify-content-between"
                                }
                              >
                                <span>
                                  <b>Boblar ({element?.name})</b>
                                </span>
                              </div>
                              <div
                                className={element?.show ? " showed " : "panel"}
                              >
                                <div
                                  className={
                                    "w-100 d-flex justify-content-between"
                                  }
                                >
                                  <span>
                                    <b>
                                      Mavzular ({element?.name} {"->"}{" "}
                                      {chapter?.name})
                                    </b>
                                  </span>
                                </div>
                                {topics?.map((topic, topicIndex) => {
                                  return (
                                    <div
                                      className={"w-100  p-2 border row-hover"}
                                      key={topicIndex}
                                      style={{ backgroundColor: "#f5f5f5" }}
                                    >
                                      <div
                                        className={
                                          "w-100 d-flex justify-content-between"
                                        }
                                      >
                                        <div className={"d-flex w-100"}>
                                          <Checkbox
                                            checked={topic?.checked}
                                            onChange={(e) =>
                                              checkLesson(
                                                topicIndex,
                                                topic,
                                                e,
                                                "topic"
                                              )
                                            }
                                            disabled={
                                              topic?.show ||
                                              topic?.count_1 +
                                                topic?.count_2 +
                                                topic?.count_3 ===
                                                0
                                            }
                                          >
                                            {topic?.name}
                                          </Checkbox>
                                        </div>
                                        <div
                                          className={
                                            "d-flex w-100 justify-content-end"
                                          }
                                        >
                                          {topic?.checked && (
                                            <InputNumber
                                              className={"input-number"}
                                              size={"large"}
                                              min={0}
                                              max={topic?.count_1}
                                              defaultValue={topic?.count_1 || 0}
                                              onChange={(e) =>
                                                onChangeInput(
                                                  e,
                                                  topic?.id,
                                                  "topic",
                                                  1
                                                )
                                              }
                                            />
                                          )}
                                          <div
                                            className={
                                              "justify-content-center align-items-center d-flex border"
                                            }
                                            style={{ width: "100px" }}
                                          >
                                            Osson:
                                            <b className="ms-1">
                                              {topic?.count_1 || 0}
                                            </b>
                                          </div>

                                          {topic?.checked && (
                                            <InputNumber
                                              className={"input-number"}
                                              size={"large"}
                                              min={0}
                                              max={topic?.count_2}
                                              defaultValue={topic?.count_2 || 0}
                                              onChange={(e) =>
                                                onChangeInput(
                                                  e,
                                                  topic?.id,
                                                  "topic",
                                                  2
                                                )
                                              }
                                            />
                                          )}
                                          <div
                                            className={
                                              "justify-content-center align-items-center d-flex border"
                                            }
                                            style={{ width: "100px" }}
                                          >
                                            O'rtacha:{" "}
                                            <b className="ms-1">
                                              {" "}
                                              {topic?.count_2 || 0}
                                            </b>
                                          </div>

                                          {topic?.checked && (
                                            <InputNumber
                                              className={"input-number"}
                                              size={"large"}
                                              min={0}
                                              max={topic?.count_3}
                                              defaultValue={topic?.count_3 || 0}
                                              onChange={(e) =>
                                                onChangeInput(
                                                  e,
                                                  topic?.id,
                                                  "topic",
                                                  3
                                                )
                                              }
                                            />
                                          )}
                                          <div
                                            className={
                                              "justify-content-center align-items-center d-flex border"
                                            }
                                            style={{ width: "100px" }}
                                          >
                                            Qiyin:
                                            <b className="ms-1">
                                              {topic?.count_3 || 0}
                                            </b>
                                          </div>
                                          <div
                                            className={
                                              "justify-content-center align-items-center d-flex border"
                                            }
                                            style={{ width: "100px" }}
                                          >
                                            Umumiy:
                                            <b className="ms-1">
                                              {topic?.count_1 +
                                                topic?.count_2 +
                                                topic?.count_3 || 0}
                                            </b>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </Col>
          </Row>

          <Row className={"mt-2"}>
            <Col xl={12} className={"w-100"}>
              <Collapse>
                <Panel header={collapseHeader} key="1">
                  {lessons &&
                    lessons?.map((element, index) => {
                      let vv = checkedItems.filter(
                        (elementCh) => elementCh?.lessonId === element?.id
                      );
                      if (vv?.length > 0) {
                        return (
                          <div
                            className={
                              "d-flex border p-2 justify-content-between"
                            }
                            key={index}
                          >
                            <p>
                              {element?.name} :{" "}
                              <b>
                                {checkedItems
                                  .filter(
                                    (elementF) =>
                                      elementF?.lessonId === element?.id
                                  )
                                  .reduce((sum, item) => {
                                    return (
                                      sum +
                                      item.questionCount.reduce(
                                        (questionSum, question) => {
                                          return (
                                            questionSum + question.elementCount
                                          );
                                        },
                                        0
                                      )
                                    );
                                  }, 0)}
                              </b>
                            </p>
                            <div className={"d-flex"}>
                              <span>
                                <b>
                                  Umumiy ball: {showLessonBall(element?.id)}
                                </b>
                              </span>

                              <Input
                                placeholder={"ball"}
                                defaultValue={1}
                                onChange={(e) =>
                                  changeLessonExamBall(element?.id, e)
                                }
                                style={{ width: "50px", height: "30px" }}
                              />
                            </div>
                          </div>
                        );
                      }
                    })}
                </Panel>
              </Collapse>
            </Col>
            <Col xl={12} className={"d-flex justify-content-end"}>
              <Button
                className={"btn-success"}
                icon={<SaveOutlined />}
                onClick={submitData}
              >
                Saqlash
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default ExamAdd;
