import React from 'react'
import API from "../../api";

export const chaptersIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/chapter',
            method: 'GET',
            params: params,
            headers: {
                'Accept': 'application/json'
            },

        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const chaptersShow = async (params = {},elementId) => {
    try {
        const response = await API({
            url: '/chapter/'+elementId,
            method: 'GET',
            params: params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const chaptersStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/chapter',
            method: 'POST',
            data: data,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const chaptersUpdate = async (elementId, data = {},params={}) => {
    try {
        const response = await API({
            url: '/chapter/' + elementId,
            method: 'PUT',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            data: data,
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const chaptersDelete = async (elementId,params = {}) => {
    try {
        const response = await API({
            url: '/chapter/' + elementId,
            method: 'DELETE',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}