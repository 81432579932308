import React, {useEffect, useState} from 'react'
import {Row, Col, Button,Pagination,Input} from "antd";
import {adminsIndex} from "../../../services/apiServices/admins_api";
import AdminsTable from "./AdminsTable";
import AdminAddModal from "./Modals/AdminAddModal";

const AdminIndex = () => {
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState();
    const [showCount, setShowCount] = useState(10);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const showAddModal = () => {
        setIsVisibleAddModal(true);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let params = {
                show_count:showCount,
                page:page,
                search:search
            }
            const response = await adminsIndex(params);
            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload]);

    const changePage = (page,pageSize) => {
        setPage(page);
        setReload(!reload);
    }

    const changeWord = (e) =>{
        setPage(1);
        setSearch(e?.target?.value);
        setReload(!reload);
    }

    return (
        <>
            <AdminAddModal setIsVisibleAddModal={setIsVisibleAddModal} isVisibleAddModal={isVisibleAddModal}
                           setReload={setReload} reload={reload}/>
            <Row className={'d-flex justify-content-between'}>
                <h5>Organizations</h5>
                <Button type={'success'} onClick={showAddModal}> Add</Button>
            </Row>
            <Row  className={'d-flex justify-content-end'}>
                <Col xl={4}>
                    <Input allowClear={true} onChange={changeWord} />
                </Col>
            </Row>
            <Row>

                <Col xl={24}>
                    <AdminsTable data={data?.data} isLoading={isLoading} setReload={setReload} reload={reload}/>
                </Col>
                <Col xl={24} className={'d-flex justify-content-end mt-2'}>
                    <Pagination defaultCurrent={data?.current_page} current={data?.current_page} total={data?.total} pageSize={showCount} onChange={changePage}/>

                </Col>
            </Row>
        </>
    )
}

export default AdminIndex;