import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Row, Col, Button, Card, Spin} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import 'moment/locale/uz-latn'
import {studentsProfileIndex, studentsResults} from "../../../../services/apiServices/students_api";
import {logoutFunction} from "../../../../services/apiServices/auth/authApiFunctions";
import MainContext from "../../../../Utils/Context/MainContext";

const ExamResult = ( ) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const {setUser,setIsAuth} = useContext(MainContext);
    useEffect(() => {
        (async () => {
            const response = await studentsResults({});
            if (response?.status === 1){
                setData(response?.result);
            }
        })()
    },[])

    return (
         <>
            <Row className={'p-1'}>
                <Col xl={12} lg={16} md={20} sm={24} className={'d-flex w-100'}>
                    <h5>Results</h5>
                </Col>
            </Row>
            {/* <Spin tip="Loading..." spinning={isLoading}>
            </Spin> */}
        </>
    )
}

export default ExamResult;