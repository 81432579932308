import React from "react";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import MyCustomUploadAdapterPlugin from "./MyCustomUploadAdapterPlugin";

const editorConfiguration = {
    toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'link',
        'bulletedList',
        'numberedList',
        '|',
        'outdent',
        'indent',
        '|',
        'imageInsert',
        // 'imageUpload',
        'blockQuote',
        'insertTable',
        'mediaEmbed',
        'undo',
        'redo',
        'alignment',
        'code',
        'codeBlock',
        'findAndReplace',
        'fontBackgroundColor',
        'fontColor',
        'fontFamily',
        'fontSize',
        'horizontalLine',
        'htmlEmbed',
        'highlight',
        'pageBreak',
        'specialCharacters',
        'sourceEditing',
        'restrictedEditingException',
        'todoList',
        'underline',
        'textPartLanguage',
        'textTransform',
    ],
    extraPlugins: [MyCustomUploadAdapterPlugin],
};

const TextEditor = (props) => {
    const {answerId, changeContent, content} = props;
    const changeData = (event, editor) => {
        changeContent(answerId, editor.getData());
    }
    return (
        <CKEditor
            data={content}
            editor={Editor}
            config={editorConfiguration}
            onReady={editor => {
                // You can store the "editor" and use when it is needed.
            }}
            onChange={(event, editor) => {
                changeData(event, editor)
            }}
        />
    )
}

export default TextEditor;


// import React from "react";
// // import Editor from 'ckeditor5-custom-build/build/ckeditor';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import {CKEditor} from '@ckeditor/ckeditor5-react';
// // import MyCustomUploadAdapterPlugin from "./MyCustomUploadAdapterPlugin";

// const editorConfiguration = {
//     toolbar: [
//         'heading',
//         '|',
//         'bold',
//         'italic',
//         'link',
//         'bulletedList',
//         'numberedList',
//         '|',
//         'outdent',
//         'indent',
//         '|',
//         'imageInsert',
//         // 'imageUpload',
//         'blockQuote',
//         'insertTable',
//         'mediaEmbed',
//         'undo',
//         'redo',
//         'alignment',
//         'code',
//         'codeBlock',
//         'findAndReplace',
//         'fontBackgroundColor',
//         'fontColor',
//         'fontFamily',
//         'fontSize',
//         'horizontalLine',
//         'htmlEmbed',
//         'highlight',
//         'pageBreak',
//         '|',
//         'specialCharacters',
//         'sourceEditing',
//         'restrictedEditingException',
//         'todoList',
//         'underline',
//         'textPartLanguage',
//         'textTransform',
//     ],
//     // extraPlugins: [MyCustomUploadAdapterPlugin],
// };

// const TextEditor = (props) => {
//     const {answerId, changeContent, content} = props;
//     const changeData = (event, editor) => {
//         changeContent(answerId, editor.getData());
//     }

//     return (
//         <CKEditor
//             data={content}
//             editor={ClassicEditor}
//             config={editorConfiguration}
//             onReady={editor => {
//                 // You can store the "editor" and use when it is needed.
//             }}
//             onChange={(event, editor) => {
//                 changeData(event, editor)
//             }}
//         />
//     )
// }

// export default TextEditor;