import React, {useState, useEffect} from "react";
import {Col, Card,Button} from "antd";
import {AiOutlineDelete, AiOutlineEdit, AiFillEye} from "react-icons/ai";
import {NavLink} from "react-router-dom";

const ExamCards = (props) => {
    const {data, isLoading, setReload, reload} = props;

    return (
        <>
            {
                data &&
                data?.map((element, index) => {
                    return (
                        <Col xl={6} className={'w-100 p-1'}>
                            <Card title={element?.name} style={{width: '100%'}}>
                                <p>Davomiyligi: <b>{element?.duration}</b></p>
                                <p>Urinishlar soni: <b>{element?.attempts_count}</b></p>
                                <p>Qiyinlik darajasi: <b>{element?.question_degree?.name}</b></p>
                                <NavLink to={`/student/exams/${element?.id}`}>
                                    <Button className={'w-100'}>Imtihonga kirish</Button>
                                </NavLink>
                            </Card>
                        </Col>
                    )
                })
            }
        </>
    )
}
export default ExamCards