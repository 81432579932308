import React, {useEffect, useState,useRef} from "react";
import {examsAttemptsResult, examsShow} from "../../../services/apiServices/exam_api";
import {useNavigate, useParams} from "react-router-dom";
import {Row, Col, Spin, Form, Select, DatePicker, Button} from "antd"
import {
    ArrowLeftOutlined,
    FileExcelOutlined,
    KeyOutlined
} from "@ant-design/icons"
import moment from "moment";
import {questionDegreeIndex} from "../../../services/apiServices/question_degree_api";
import {groupsIndex} from "../../../services/apiServices/group_api";
import {langIndex} from "../../../services/apiServices/lang_api";
import ExamShowTable from "./ExamShowTable";
import ExamShowForm from "./ExamShowForm";
import {exportExamAttemptsToExcel, exportToExcel} from "../../../services/export/exportFunction";
import {PrintPasswordsComponent} from "./PrintPasswordsComponent";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from 'react-to-print';

const ExamShow = () => {
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [questionDegrees, setQuestionDegrees] = useState([]);
    const [disableAllInputs, setDisableAllInputs] = useState(true);
    const [attempts, setAttempts] = useState([]);
    const [langs, setLangs] = useState([]);
    const [groups, setGroups] = useState([]);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const location = useParams()
    const exportExcel = () => {
        let select = ['fio as Familiya ismi sharifi', 'x'];
        let params = {
            select: JSON.stringify(select)
        }
        exportExamAttemptsToExcel('/exam-attempts-result/' + location?.id, 'students', params);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const response = await examsShow({}, location?.id);
            setData(response?.result?.exam);
            const responseQuestionDegree = await questionDegreeIndex({show_count: 'all'})
            setQuestionDegrees(responseQuestionDegree?.result)
            const responseGroups = await groupsIndex({show_count: 'all'})
            setGroups(responseGroups?.result?.data)
            const responseLang = await langIndex({show_count: 'all'})
            setLangs(responseLang?.result)
            const formData = {
                name: response?.result?.exam?.name,
                attempts_count: response?.result?.exam?.attempts_count,
                duration: moment(response?.result?.exam?.duration, 'HH:mm:ss'),
                question_degree_id: response?.result?.exam?.question_degree_id,
                group_id: parseInt(response?.result?.exam?.group_id),
                status: response?.result?.exam?.status,
                is_protected: response?.result?.exam?.is_protected,
                show_correct_answers: response?.result?.exam?.show_correct_answers,
                lang_id: response?.result?.exam?.lang_id,
            };
            if (response?.result?.exam?.status) {
                Object.assign(formData, {
                        exam_start_end_time: [moment(response?.result?.exam?.start_time, 'YYYY-MM-DD HH:mm:ss'), moment(response?.result?.exam?.end_time, 'YYYY-MM-DD HH:mm:ss')]
                    }
                )
            }
            form.setFieldsValue(formData)
            const responseAttempts = await examsAttemptsResult(location?.id, {});
            setAttempts(responseAttempts?.result?.data);
            setIsLoading(false)

        })()
    }, [])
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
    return (
        <>
            <Row className={'d-flex justify-content-between'}>
                <div className={'d-flex'}>
                 <span onClick={() => navigate(-1)}>
                     <ArrowLeftOutlined className={'go-back-button'}/>
                 </span>
                    <h5>Imtihonni ko'rish</h5>
                </div>
                <div className={'d-flex'}>

                </div>
            </Row>

            <Spin spinning={isLoading} tip={'Iltimos kuting'}>
                <ExamShowForm form={form} data={data} disableAllInputs={disableAllInputs} langs={langs} groups={groups}
                              questionDegrees={questionDegrees}/>
            </Spin>
            <Row>
                {
                    data?.is_protected == 1 &&
                    <Col xl={4}> <Button onClick={handlePrint} className={'w-100'} icon={<KeyOutlined/>}>Parollarni chop qilish</Button>
                    </Col>
                }
                <Col xl={4}> <Button onClick={exportExcel} className={'w-100'}
                                     icon={<FileExcelOutlined className={'text-success'}/>}>Natijalarni excelga export
                    qilish</Button> </Col>
            </Row>
            <Row className={'pt-3'}>
                <ExamShowTable data={attempts} isLoading={isLoading}/>
            </Row>
            <Row>
                <div>
                    <PrintPasswordsComponent attempts={attempts} exam={data} ref={componentRef}/>
                </div>
            </Row>
        </>
    )
}
export default ExamShow