/** @format */

import React, { useEffect, useState } from "react";
import { Row, Col, Button, Pagination, Input } from "antd";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  ArrowDownOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import { topicsShow } from "../../../services/apiServices/topics_api";
import QuestionTable from "../../Question/crud/QuestionTable";

const TopicShow = () => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState();
  const [showCount, setShowCount] = useState(10);
  const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lesson, setLesson] = useState();
  const navigate = useNavigate();
  const location = useParams();
  const showAddModal = () => {
    setIsVisibleAddModal(true);
  };
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let orderBy = {
        field: "name",
        value: "ASC",
      };
      let params = {
        show_count: showCount,
        page: page,
        search: search,
        orderBy: JSON.stringify(orderBy),
      };
      const response = await topicsShow(params, location?.id);
      setData(response?.result);
      setIsLoading(false);
    })();
  }, [reload]);

  const changePage = (page, pageSize) => {
    setPage(page);
    setReload(!reload);
  };
  const changePageCount = (current, size) => {
    setShowCount(size);
    setReload(!reload);
  };

  const changeWord = (e) => {
    setPage(1);
    setSearch(e?.target?.value);
    setReload(!reload);
  };

  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <div className={"d-flex"}>
          <div className={"d-flex"}>
            <span onClick={() => navigate(-1)}>
              <ArrowLeftOutlined className={"go-back-button"} />
            </span>
            <h5>
              Savollar ({data?.lesson?.name}/{data?.chapter?.name}/
              {data?.topic?.name})
            </h5>
          </div>
        </div>
        <div className={"d-flex"}>
          <NavLink to={`/add-question/${data?.topic?.id}`}>
            {" "}
            <Button type={"success"}>
              {" "}
              Yangi qo'shish <PlusOutlined className={"text-success"} />
            </Button>
          </NavLink>
          <NavLink to={`/import-question/${data?.topic?.id}`}>
            {" "}
            <Button type={"success"} icon={<ArrowDownOutlined />}>
              {" "}
              Savollarni Exceldan import qilish{" "}
              <FileExcelOutlined className={"text-success"} />
            </Button>
          </NavLink>
        </div>
      </Row>
      <Row className={"d-flex justify-content-end mt-2"}>
        <Col xl={4}>
          <Input allowClear={true} onChange={changeWord} />
        </Col>
      </Row>
      <Row>
        <Col xl={24}>
          <QuestionTable
            data={data?.questions?.data}
            isLoading={isLoading}
            setReload={setReload}
            reload={reload}
          />
        </Col>
        <Col span={24} className={"d-flex justify-content-end mt-2"}>
          <Pagination
            defaultCurrent={data?.questions?.current_page}
            defaultPageSize={data?.questions?.per_page}
            current={page}
            total={data?.questions?.total}
            pageSize={showCount}
            onChange={changePage}
            onShowSizeChange={changePageCount}
            showSizeChanger
            pageSizeOptions={["10", "15", "20", "30", "50", "100"]}
          />
        </Col>
      </Row>
    </>
  );
};

export default TopicShow;
