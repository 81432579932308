import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Pagination, Input} from "antd";
import {NavLink} from "react-router-dom";
import {
    FileExcelOutlined,
    PlusOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined
} from "@ant-design/icons"
import {lessonsIndex} from "../../../services/apiServices/lesson_api";
import LessonAddModal from "./Modals/LessonAddModal";
import LessonsTable from "./LessonsTable";
const LessonsIndex = () => {
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState();
    const [showCount, setShowCount] = useState(10);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const showAddModal = () => {
        setIsVisibleAddModal(true);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let orderBy={
                field:'id',
                value:'DESC'
            }
            let additional_relations_count = ['questions']
            let params = {
                show_count: showCount,
                page: page,
                search: search,
                orderBy:JSON.stringify(orderBy),
                additional_relations_count:JSON.stringify(additional_relations_count)
            }
            const response = await lessonsIndex(params);
            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload]);

    const changePage = (page, pageSize) => {
        setPage(page);
        setReload(!reload);
    }

    const changeWord = (e) => {
        setPage(1);
        setSearch(e?.target?.value);
        setReload(!reload);
    }

    return (
        <>
            <LessonAddModal setIsVisibleAddModal={setIsVisibleAddModal} isVisibleAddModal={isVisibleAddModal}
                            setReload={setReload} reload={reload}/>
            <Row className={'d-flex justify-content-between'}>
                <h5>Fanlar</h5>
                <div className={'d-flex'}>
                    <Button type={'success'} onClick={showAddModal} className={'btn-outline-success'} icon={<PlusOutlined  />}> Yangi qo'shish </Button>
                </div>
            </Row>
            <Row className={'d-flex justify-content-end mt-2'}>
                <Col xl={4}>
                    <Input allowClear={true} onChange={changeWord}/>
                </Col>
            </Row>
            <Row >

                <Col xl={24}>
                    <LessonsTable data={data?.data} isLoading={isLoading} setReload={setReload} reload={reload}/>
                </Col>
                <Col xl={24} className={'d-flex justify-content-end mt-2'}>
                    <Pagination defaultCurrent={data?.current_page} current={data?.current_page} total={data?.total}
                                pageSize={showCount} onChange={changePage}/>

                </Col>
            </Row>
        </>
    )
}

export default LessonsIndex;