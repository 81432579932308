import React,{useState,useEffect} from "react";
import {Table,Popconfirm,message} from "antd";
import {AiOutlineDelete, AiOutlineEdit, AiFillEye} from "react-icons/ai";
import {NavLink} from "react-router-dom";
import {examsDelete} from "../../../services/apiServices/exam_api";


const ExamTable = (props) => {
    const {data, isLoading, setReload, reload} = props;
    let columns = [
        {
            title: 'Nomi',
            render:(index,element) => <NavLink to={`/exams/${element?.id}`}>{element?.name}</NavLink>
        },
        {
            title: 'Guruh',
            render:(index,element) => <>{element?.group?.name}</>
        },
        {
            title: 'Urinishlar soni',
            render:(index,element) => <>{element?.attempts_count}</>
        },
         {
            title: 'Davomiyligi',
            render:(index,element) => <>{element?.duration}</>
        },
        {
            title: 'Amallar',
            render: (index, element) => <div className="d-flex action-box">
                <Popconfirm placement="left" title={'Ma`lumotni o`chirasizmi'} onConfirm={() => deleteElement(element?.id)}
                            okText="Ha" cancelText="Yo`q">
                    <span className={'text-danger'}><AiOutlineDelete/></span>
                </Popconfirm>
            </div>
        }
    ];
    const deleteElement = (elementId) => {
        if (elementId) {
            (async () => {
                const response = await examsDelete(elementId);
                if (response) {
                    message.success('Ma`lumot o`chirildi');
                    setReload(!reload);
                }
            })()
        }
    }

    return (
        <>
            <Table columns={columns} dataSource={data} pagination={false} loading={isLoading}/>
        </>
    )
}
export default ExamTable;