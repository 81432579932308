import React, {useContext, useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Row, Col, Button, Input, Card, Form, Spin} from "antd";
import {
    PlusOutlined,
    ArrowLeftOutlined,
    LogoutOutlined
} from "@ant-design/icons";
import moment from "moment";
import 'moment/locale/uz-latn'
import {studentsProfileIndex} from "../../../../services/apiServices/students_api";
import {logoutFunction} from "../../../../services/apiServices/auth/authApiFunctions";
import MainContext from "../../../../Utils/Context/MainContext";

const ProfileIndex = () => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState();
    const {setUser,setIsAuth} = useContext(MainContext);
    useEffect(() => {
        (async () => {
            const response = await studentsProfileIndex({});
            if (response?.status === 1){
                setData(response?.result);
            }
        })()
    },[])
    const logoutUser = () => {
        (async ()=>{
            const response = await logoutFunction(setIsAuth,setUser)
            if (response){
                navigate('/login')
            }
        })()
    }
    return (
         <>
            <Row className={'p-1 d-flex justify-content-center '}>
                <Col xl={12} lg={16} md={20} sm={24} className={'d-flex w-100'}>

                    <h5>{data?.student?.fio}</h5>
                </Col>
            </Row>
            <Spin tip="Loading..." spinning={isLoading}>
                <Row className={'p-1 d-flex justify-content-center '}>
                    <Col xl={12} lg={16} md={20} sm={24} className={' w-100'}>
                        <Card
                            // title={'Profil ma`lumotlari'}
                            className={'w-100'}
                            cover={<div className={'d-flex p-3 border-bottom justify-content-between'}><span>Profil ma`lumotlari</span> <Button onClick={logoutUser} icon={<LogoutOutlined />}>Chiqish  </Button></div>}
                        >
                            <p>F.I.O: <b>{data?.student?.fio}</b></p>
                            <p>Unikal raqam: <b>{data?.student?.unique_number}</b></p>
                            <p>Tashkilot: <b>{data?.student?.organization?.name}</b></p>
                            <p>Biriktirilgan guruhlar: <b>
                                {
                                    data?.groups?.map((element) => {
                                        return (<>{element?.name} , </>)
                                    })
                                }
                            </b></p>
                        </Card>
                    </Col>
                </Row>
            </Spin>
        </>
    )
}

export default ProfileIndex