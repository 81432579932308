import React from "react";
import { Table, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

const SelectedStudentsTable = (props) => {
  const {
    selectedStudents,
    setSelectedStudents,
    selectedKeys,
    setSelectedKeys,
    isLoadingImport,
  } = props;

  const cancelSelected = (element) => {
    let items = selectedStudents?.filter(
      (student) =>
        (student?.student?.id ? student?.student?.id : student?.id) !==
        element?.id
    );
    setSelectedStudents(items);
    let keys = selectedKeys?.filter(function (key) {
      return key !== element?.unique_number;
    });
    setSelectedKeys(keys);
  };

  let columns = [
    {
      title: "F.I.O",
      key: "fio",
      render: (row) => row.student?.fio || row?.fio,
    },
    {
      title: "Unikal raqami",
      key: "unique_number",
      render: (row) => row.student_unique_number || row?.unique_number,
    },
    {
      title: "Action",
      render: (_, element) => {
        // console.log("element", element);
        return (
          <>
            <Button
              onClick={() =>
                cancelSelected(element?.student ? element?.student : element)
              }
              danger
              icon={<CloseOutlined />}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        loading={isLoadingImport}
        style={{ maxHeight: "60vh", overflow: "hidden", overflowY: "scroll" }}
        dataSource={selectedStudents}
        columns={columns}
        pagination={false}
      />
    </>
  );
};

export default SelectedStudentsTable;
