import React, { useContext } from "react";
import { Breadcrumb } from "antd";
import MainContext from "../../Utils/Context/MainContext";

const BreadCrumbs = ({ breadcrumbs }) => {
  return (
    <Breadcrumb>
      {breadcrumbs &&
        breadcrumbs?.map((element) => {
          return (
            <Breadcrumb.Item>
              <a href="">{element?.title}</a>
            </Breadcrumb.Item>
          );
        })}
    </Breadcrumb>
  );
};

export default BreadCrumbs;
