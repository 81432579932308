/** @format */
import { MathJax, MathJaxContext } from "better-react-mathjax";

const MathJaxWrapper = ({children}) => {

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
        ["\(", "\)"]
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
        ["\[", "\]"]
      ]
    }
  }

  return (
    <MathJaxContext config={config} version={3} >
      <MathJax>{children}</MathJax>
    </MathJaxContext>
  );
};

export default MathJaxWrapper;