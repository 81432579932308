import React, {useEffect, useState} from "react";
import {examsIndex, examsShow} from "../../../../services/apiServices/exam_api";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {Row, Col, Button, Input, Card, Form, Spin} from "antd";
import {
    PlusOutlined,
    ArrowLeftOutlined
} from "@ant-design/icons";
import {studentEnterExam} from "../../../../services/apiServices/student_exam_api";
import moment from "moment";
import 'moment/locale/uz-latn'

const ExamShowStudent = () => {
    const [form] = Form.useForm();
    const [data, setData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const navigate = useNavigate();
    const location = useParams();
    moment.locale('uz-latn');
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            const response = await examsShow({}, location?.id);
            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload])
    const onFinish = (values) => {
        const data = new FormData();
        for (const key in values) {
            data.append(key, values[key])
        }
        data.append('exam_id', location?.id);
        (async () => {
            const response = await studentEnterExam(data, {});
            if (response?.status) {
                // const response
                navigate('/student-exam/start/' + location?.id)
            }
        })()
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const enterExam = () => {
        form.submit();
    }
    return (
        <>
            <Row className={'p-1 d-flex justify-content-center '}>
                <Col xl={12} lg={16} md={20} sm={24} className={'d-flex w-100'}>
                     <span onClick={() => navigate(-1)}>
                     <ArrowLeftOutlined className={'go-back-button'}/>
                 </span>
                    <h5>{data?.exam?.name}</h5>
                </Col>
            </Row>
            <Spin tip="Loading..." spinning={isLoading}>
                <Row className={'p-1 d-flex justify-content-center '}>
                    <Col xl={12} lg={16} md={20} sm={24} className={' w-100'}>
                        <Card title={'Imtihonga kirish'} className={'w-100'}>

                            <Form
                                form={form}
                                name="basic"
                                labelCol={{
                                    span: 24,
                                }}
                                wrapperCol={{
                                    span: 24,
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                {
                                    data?.exam?.is_protected == 1 ?
                                        <Form.Item
                                            label="Imtihonga kirish paroli"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input your username!',
                                                },
                                            ]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                        : <></>
                                }
                                <Button className={'btn-outline-success w-100'} onClick={enterExam}>Kirish</Button>

                            </Form>
                        <p> Imtihon nomi: <b>{data?.exam?.name}</b></p>
                        <p>Imtihon davomiyligi: <b>{data?.exam?.duration}</b></p>
                        <p>Imtihon faol turish davri: <br/>
                            <b>{data?.exam?.start_time} dan <br/>{data?.exam?.end_time} gacha</b></p>
                        <p>Imtihon umumiy urinishlar soni: <b>{data?.exam?.attempts_count}</b></p>
                        <p>Imtihonda qolgan urinishlar soni: <b>{data?.exam_student?.attempts_count - data?.attempts?.length}</b></p>
                        <p>Imtihon holati: <b>
                            {
                                data?.exam?.status === 'active' ? 'faol' : 'faol emas'
                            }
                        </b></p>
                        </Card>
                    </Col>
                </Row>
                <Row className={'p-1 d-flex justify-content-center '}>
                    <Col xl={12} lg={16} md={20} sm={24}  className={' w-100'} >
                        <div className={'w-100 text-center'}>Urunishlar</div>
                        {
                            data?.attempts &&
                            data?.attempts?.map((attempt, index) => {
                                return (
                                    <Card title={moment(attempt?.created_at).format('MMMM Do YYYY, dddd, HH:mm:ss')} className={'w-100'}>
                                        <p>Savollar soni: <b>{attempt?.question_count}</b></p>
                                        <p>To`g`ri javoblar soni: <b>{attempt?.correct_answers_count}</b></p>
                                        <Button className={'w-100'}>Urinish tavsilotlarini ko`rish</Button>
                                    </Card>
                                )
                            })
                        }
                    </Col>
                </Row>
            </Spin>
        </>
    )
}

export default ExamShowStudent;