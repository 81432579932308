/** @format */

import React, { useEffect, useState } from "react";
import {
  Row,
  Button,
  Col,
  Form,
  Select,
  Radio,
  Popconfirm,
  Checkbox,
  message,
  Card,
} from "antd";
import {
  ArrowLeftOutlined,
  PlusOutlined,
  SaveOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import TextEditor from "../../../components/Editor/TextEditor";
import { questionDegreeIndex } from "../../../services/apiServices/question_degree_api";
import { langIndex } from "../../../services/apiServices/lang_api";
import { questionStore } from "../../../services/apiServices/question_api";
// import MathJaxWrapper from "../../../components/Editor/mathJax";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import MathJaxWrapper from "../../../components/Editor/mathJax";

const QuestionAdd = () => {
  const location = useParams();
  const { Option } = Select;
  const [editorData, setEditorData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [typeQuestion, setTypeQuestion] = useState("single");
  const [answers, setAnswers] = useState([
    {
      answerId: 1,
      content: "",
      isCorrect: true,
      isDeleted: false,
    },
    {
      answerId: 2,
      content: "",
      isCorrect: false,
      isDeleted: false,
    },
  ]);
  const [questionDegrees, setQuestionDegrees] = useState([]);
  const [lang, setLang] = useState([]);
  useEffect(() => {
    (async () => {
      const degreeResponse = await questionDegreeIndex();
      const langResponse = await langIndex();
      setQuestionDegrees(degreeResponse?.result);
      setLang(langResponse?.result);
    })();
  }, []);
  const [addForm] = Form.useForm();
  const navigate = useNavigate();
  const onFinish = (values) => {
    const data = new FormData();
    for (const valuesKey in values) {
      data.append(valuesKey, values[valuesKey] ? values[valuesKey] : "");
    }
    data.append(
      "answers",
      JSON.stringify(answers?.filter((element) => !element.isDeleted))
    );
    data.append("content", editorData ? editorData : "");
    data.append("topic_id", location.id);
    (async () => {
      const response = await questionStore({}, data);
      if (response) {
        message.success("Ma`lumot saqlandi");
        navigate(-1);
      }
    })();
  };
  const onFinishFailed = (errorInfo) => {};
  const saveQuestion = () => {
    addForm.submit();
  };
  const changeQuestionContent = (answerId, data) => {
    setEditorData(data);
  };
  const changeAnswerContent = (answerId, data) => {
    setAnswers(
      answers.map((element) => {
        if (element.answerId === answerId) {
          return { ...element, content: data };
        } else {
          return element;
        }
      })
    );
  };
  const onChangeCorrect = (answerId) => {
    if (typeQuestion === "single") {
      setAnswers(
        answers.map((element) => {
          if (element.answerId == answerId) {
            return { ...element, isCorrect: true };
          } else {
            return { ...element, isCorrect: false };
          }
        })
      );
    }
    if (typeQuestion === "multi") {
      setAnswers(
        answers.map((element) => {
          if (element.answerId == answerId) {
            if (
              answers.filter((element) => element.isCorrect).length > 1 ||
              !element.isCorrect
            ) {
              return { ...element, isCorrect: !element.isCorrect };
            } else {
              return element;
            }
          } else {
            return element;
          }
        })
      );
    }
  };
  const addAnswer = () => {
    setAnswers((prevState) => [
      ...prevState,
      {
        answerId: prevState[prevState.length - 1].answerId + 1,
        content: "",
        isCorrect: false,
        isDeleted: false,
      },
    ]);
    // setAnswers([...answers, {answerId: answers[answers.length - 1].answerId + 1, content: '', isCorrect: false}]);
  };
  const removeAnswer = (answerId) => {
    setAnswers(
      answers.map((element) => {
        if (element.answerId === answerId) {
          return { ...element, content: "", isDeleted: true };
        } else {
          return element;
        }
      })
    );
    // setAnswers(prevState => ([...prevState?.filter(item => item.answerId !== answerId)]))
  };
  const changeTypeQuestion = (value) => {
    setTypeQuestion(value);
    if (value === "single") {
      setAnswers(
        answers
          .filter((element) => !element.isDeleted)
          .map((element, index) => {
            if (index === 0) {
              return { ...element, isCorrect: true };
            } else {
              return { ...element, isCorrect: false };
            }
          })
      );
    }
  };
  // console.log('question content', typeQuestion);
  // console.log(editorData);
  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <div className={"d-flex"}>
          <span onClick={() => navigate(-1)}>
            <ArrowLeftOutlined className={"go-back-button"} />
          </span>
          <h5>Savol qo`shish</h5>
        </div>
        <div className={"d-flex"}>
          <Button
            className={"btn-outline-success"}
            icon={<SaveOutlined />}
            onClick={saveQuestion}
          >
            {" "}
            Saqlash
          </Button>
        </div>
      </Row>
      <Form
        name="basic"
        form={addForm}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 23,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xl={4}>
            <Form.Item
              label="Savol turi"
              name="type"
              rules={[{ required: true, message: "Savol turini tanlang!" }]}
              initialValue={"single"}
            >
              <Select
                showSearch
                placeholder="Savol turini tanlang"
                optionFilterProp="children"
                onChange={(e) => changeTypeQuestion(e)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="single">Bir tanlovli</Option>
                <Option value="multi">Ko'p tanlovli</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xl={4}>
            <Form.Item
              label="Savol qiyinlik darajasi"
              name="question_degree_id"
              rules={[
                {
                  required: true,
                  message: "Savol qiyinlik darajasini tanlang!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Savol turini tanlang"
                optionFilterProp="children"
                defaultActiveFirstOption={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {questionDegrees &&
                  questionDegrees?.map((element, index) => {
                    return (
                      <Option key={index} value={element?.id}>
                        {element?.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={4}>
            <Form.Item
              label="Savol tili"
              name="lang_id"
              rules={[{ required: true, message: "Savol tilini tanlang!" }]}
            >
              <Select
                showSearch
                placeholder="Savol tilini tanlang"
                optionFilterProp="children"
                defaultActiveFirstOption={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {lang &&
                  lang?.map((element, index) => {
                    return (
                      <Option key={index} value={element?.id}>
                        {element?.name}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="Savol matni" name="content">
              <div className={"border p-1"}>
                <TextEditor
                  changeContent={changeQuestionContent}
                  answerId={0}
                  typeEditor={"question"}
                  content={editorData}
                />
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Row className={"d-flex justify-content-between"}>
          <Col xl={6}>
            <h6>Javoblar</h6>
          </Col>
          <Col xl={6} className={"d-flex justify-content-end"}>
            <Button icon={<PlusOutlined />} onClick={() => addAnswer()}>
              Javob qo`shish
            </Button>
          </Col>
        </Row>
        {answers?.map((element, index) => {
          if (!element.isDeleted) {
            return (
              <Row gutter={24} key={index} className={"border p-1"}>
                <Col sm={12} md={12} lg={2} xl={2} className={""}>
                  {typeQuestion == "single" ? (
                    <Radio
                      value={element.answerId}
                      name={"dsd"}
                      checked={element.isCorrect}
                      onChange={() => onChangeCorrect(element.answerId)}
                    >
                      Javob
                    </Radio>
                  ) : (
                    <Checkbox
                      value={element.answerId}
                      checked={element.isCorrect}
                      onChange={() => onChangeCorrect(element.answerId)}
                    >
                      Javob
                    </Checkbox>
                  )}
                </Col>
                <Col sm={12} md={12} lg={2} xl={1}>
                  {answers?.filter((element) => !element.isDeleted).length >
                    2 && (
                    <Popconfirm
                      title={"O`chirasizmi ?"}
                      placement={"top"}
                      onConfirm={() => removeAnswer(element.answerId)}
                    >
                      <Button icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                  )}
                </Col>
                <Col
                  sm={24}
                  md={24}
                  lg={20}
                  xl={21}
                  className={"border p-1 mt-1"}
                >
                  <TextEditor
                    changeContent={changeAnswerContent}
                    answerId={element.answerId}
                    typeEditor={"answer"}
                    content={element.content}
                  />
                </Col>
              </Row>
            );
          }
        })}
      </Form>
      <Card
        title={
          <div className="d-flex overflow-auto">
            <p className="me-2 p-0">
              <b>Savol: </b>
            </p>
            <MathJaxWrapper>
              <div dangerouslySetInnerHTML={{ __html: editorData }} />
            </MathJaxWrapper>
          </div>
        }
        className="w-100 rounded rounded-3 mt-3"
      >
        <div className="overflow-auto">
          {answers?.map((element, index) => (
            <p className="d-flex">
              {" "}
              <span className="me-2">{index + 1})</span>{" "}
              <MathJaxWrapper>
                {" "}
                <div dangerouslySetInnerHTML={{ __html: element.content }} />
              </MathJaxWrapper>
            </p>
          ))}
        </div>
      </Card>
    </>
  );
};

export default QuestionAdd;
