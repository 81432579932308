import React, { useState, useEffect } from "react";
import { Row, Col, Button, Input, Pagination } from "antd";
import { NavLink } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import ExamTable from "./ExamTable";
import { examsIndex } from "../../../services/apiServices/exam_api";

const ExamIndex = () => {
  const [data, setData] = useState();
  const [search, setSearch] = useState();
  const [showCount, setShowCount] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [reload, setReload] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let orderBy = {
        field: "id",
        value: "DESC",
      };
      let params = {
        show_count: showCount,
        page: page,
        search: search,
        orderBy: JSON.stringify(orderBy),
      };
      const response = await examsIndex(params);
      if (response) {
        setData(response?.result);
      }
      setIsLoading(false);
    })();
  }, [reload]);
  const changePage = (page, pageSize) => {
    setPage(page);
    setReload(!reload);
  };

  const changeWord = (e) => {
    setPage(1);
    setSearch(e?.target?.value);
    setReload(!reload);
  };
  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <h5>Imtihonlar</h5>
        <div className={"d-flex"}>
          <NavLink to={"/exams-add"}>
            <Button
              success
              icon={<PlusOutlined />}
              className={"btn-outline-success"}
            >
              {" "}
              Yangi qo'shish{" "}
            </Button>
          </NavLink>
        </div>
      </Row>
      <Row className={"d-flex justify-content-end mt-2"}>
        <Col xl={4}>
          <Input allowClear={true} onChange={changeWord} />
        </Col>
      </Row>
      <Row>
        <Col xl={24}>
          <ExamTable
            data={data?.data}
            isLoading={isLoading}
            setReload={setReload}
            reload={reload}
          />
        </Col>
        <Col xl={24} className={"d-flex justify-content-end mt-2"}>
          <Pagination
            defaultCurrent={data?.current_page}
            current={data?.current_page}
            total={data?.total}
            pageSize={showCount}
            onChange={changePage}
          />
        </Col>
      </Row>
    </>
  );
};
export default ExamIndex;
