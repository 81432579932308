import React, {useState} from 'react'
import {Table, Popconfirm, message, Form,Pagination,Row} from "antd"
import {AiOutlineDelete, AiOutlineEdit, AiFillEye} from "react-icons/ai";
import {organizationsDelete} from "../../../services/apiServices/organizations_api";
import OrganizationEditModal from "./Modals/OrganizationEditModal";

const OrganizationsTable = (props) => {
    const [form] = Form.useForm();
    const {data, isLoading, setReload, reload} = props;
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [selectedElement, setSelectedElement] = useState();
    let columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: 'Action',
            render: (index, element) => <div className="d-flex action-box">
                <Popconfirm placement="left" title={'Ma`lumotni o`chirasizmi'} onConfirm={() => deleteRole(element?.id)}
                            okText="Ha" cancelText="Yo`q">
                    <span className={'text-danger'}><AiOutlineDelete/></span>
                </Popconfirm>
                <span className={'text-warning'} onClick={() => showEditModal(element)}><AiOutlineEdit/></span>
                <span className={'text-primary'}><AiFillEye/></span>
            </div>
        }
    ];
    const deleteRole = (elementId) => {
        if (elementId) {
            (async () => {
                const response = await organizationsDelete(elementId);
                if (response) {
                    message.success('Ma`lumot o`chirildi');
                    setReload(!reload);
                }
            })()
        }
    }
    const showEditModal = (element) => {
        form.setFieldsValue(element);
        setIsEditModalVisible(true);
        setSelectedElement(element);
    };
    return (
        <>
            <OrganizationEditModal
                setIsEditModalVisible={setIsEditModalVisible}
                isEditModalVisible={isEditModalVisible}
                selectedElement={selectedElement}
                form={form}
                setReload={setReload}
                reload={reload}
            />
            <Table columns={columns} dataSource={data} pagination={false} loading={isLoading}/>
        </>
    )
}

export default OrganizationsTable;