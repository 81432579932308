import React, {useState, useEffect} from "react";
import {Row, Button, Col, Upload, message, Form, Spin, Tooltip, Select} from "antd";
import {
    InboxOutlined,
    ArrowLeftOutlined,
    UploadOutlined,
    MinusCircleOutlined,
    PlusCircleOutlined,
    DownloadOutlined
} from "@ant-design/icons"
import {useNavigate, useParams} from "react-router-dom";
import {importQuestions, saveImportQuestions} from "../../../../services/apiServices/question_api";
import "./question_import_style.css"
import {questionTypesIndex} from "../../../../services/apiServices/type_question_api";
import {questionDegreeIndex} from "../../../../services/apiServices/question_degree_api";
import {langIndex} from "../../../../services/apiServices/lang_api";
import {PATH_PREFIX_FILE} from "../../../../Utils/AppVariables";
import MathJaxWrapper from "../../../../components/Editor/mathJax";

const QuestionImportIndex = () => {
    const [form] = Form.useForm();
    const [formSave] = Form.useForm();
    const {Option} = Select;
    const {Dragger} = Upload;
    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]);
    const [data, setData] = useState();
    const [questions, setQuestions] = useState([]);
    const location = useParams();
    const [questionTypes, setQuestionTypes] = useState([
        {
            id: 'single',
            name: 'Bir tanlovli'
        },
        {
            id: 'multi',
            name: 'Ko`p tanlovli'
        }
    ]);
    const [questionDegrees, setQuestionDegrees] = useState([]);
    const [questionLang, setQuestionLang] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSpin, setIsLoadingSpin] = useState(false);
    const props = {
        name: 'file',
        multiple: false,
        // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        onChange(info) {
            setFileList([{
                uid: '-1',
                name: info.file.name,
                status: 'ok',
                url: URL.createObjectURL(info.file.originFileObj)
            }])
        },
        onDrop(e) {
            setFileList([{
                uid: '-1',
                name: e.dataTransfer.files[0]?.name,
                status: 'ok',
                url: URL.createObjectURL(e.dataTransfer.files[0])
            }])
            console.log('Dropped files', e.dataTransfer.files);
        },
        onRemove() {
            setFileList([]);
        }
    };
    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('file', values?.file?.file?.originFileObj);
        (async () => {
            setIsLoading(true)
            const response = await importQuestions(formData);
            if (response) {
                setData(response?.result);
                setQuestions(response?.result?.questions)
                setIsLoading(false)
            }
        })()
    }
    const readFile = () => {
        form.submit();
    }
    const onFinishFailed = (er) => {
        console.log('val er', er);
    }
    let columns = [
        {
            title: 'F.I.O',
            render: (index, element) => <>{element}</>
        }
    ]
    const submitQuestions = () => {
        formSave.submit();
    }
    const saveAllStudents = (values) => {
        if (data?.questions?.length) {
            (async () => {
                setIsLoadingSpin(true)
                const formData = new FormData();
                for (const key in values) {
                    formData.append(key, values[key])
                }
                formData.append('questions', JSON.stringify(questions))
                formData.append('topic_id', location?.id)
                const response = await saveImportQuestions(formData);
                if (response) {
                    message.success('Ma`lumot saqlandi');
                    setFileList([]);
                    setData();
                    navigate('/topics/'+location?.id)
                }
                setIsLoadingSpin(false)
            })()
        }
    }
    // console.log('dd' , data?.students)
    const questionAddRemove = (elementSelected, elementIndex) => {
        let resSel = 0;
        if (elementSelected?.selected === 1) {
            resSel = 0;
        } else {
            resSel = 1;
        }
        setQuestions(prevState => questions?.map((element, index) => {
            if (index === elementIndex) {
                return {...element, selected: resSel}
            } else {
                return element;
            }
        }))
    }
    useEffect(() => {
        (async () => {
            const questionDegreeResponse = await questionDegreeIndex({});
            setQuestionDegrees(questionDegreeResponse?.result)
            const questionLangResponse = await langIndex();
            setQuestionLang(questionLangResponse?.result)
        })()
    }, [])
    return (
        <>
            <Row className={'d-flex justify-content-between'}>
                <div className={'d-flex'}>
                         <span onClick={() => navigate(-1)}>
                     <ArrowLeftOutlined className={'go-back-button'}/>
                 </span> <h5>Savollarni import qilish</h5>

                </div>
            </Row>
            <Spin tip="Ma`lumot saqlanmoqda" spinning={isLoadingSpin}>
                <Row>
                    <Col xl={24}>
                        <div className={'text-danger'}><b>Savollarni import qilish uchun aniq shablondan foydalaning va
                            excel faylingizni shu shablon orqali to`ldiring
                            <a href={PATH_PREFIX_FILE+'/files/question/template/excel/question_import_excel_template.xlsx'}> Shablon ni yuklab olish <DownloadOutlined /> </a>
                        </b>
                        </div>
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label=""
                                name="file"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Faylni tanlang!',
                                    },
                                ]}
                            >
                                <Dragger customRequest={({onSuccess}) => {
                                    onSuccess('ok')
                                }} {...props} accept={'.xls,.xlsx'} fileList={fileList}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined/>
                                    </p>
                                    <p className="ant-upload-text">Buyerni bosib tanlang yoki excel faylni shu yerga
                                        tashlang <br/> (<span className={'text-danger'}>Excel fayldan bir martada import qilish mumkin bo`lgan savollarning maksimal soni 150 ta!. Undan oshgan holatda ortiqchalari import qilinmaydi</span>)
                                    </p>
                                </Dragger>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col xl={24} className={'d-flex justify-content-end'}>
                        <Button disabled={fileList?.length == 0 ? true : false} onClick={readFile} loading={isLoading}>Fayldagi
                            ma`lumotlarni
                            o`qish</Button>
                    </Col>

                </Row>
            </Spin>
            <Spin tip="Ma`lumot o`qilmoqda" spinning={isLoading}>
                <Row className={'mt-3 border p-4'}>
                    {
                        data?.questions &&
                        <>
                            <Col xl={12}>

                            </Col>
                            <Col xl={12} className={'d-flex justify-content-end'}>
                                <Button className={'btn-outline-success'} loading={isLoadingSpin}
                                        disabled={isLoadingSpin} onClick={submitQuestions}>Barcha savollarni
                                    saqlash</Button>
                            </Col>
                        </>
                    }
                    <Col xl={24}>
                        {
                            questions?.length > 0 &&
                            <>
                                <div className={'text-center'}>
                                    <p>Import qilish mumkin bo'lgan maksimal savollar soni: <b>{data?.maxSize}</b></p>
                                    <p>Fayldagi umumiy savollar soni: <b>{data?.count}</b></p>
                                    <p className={'text-danger'}>Maksimal qiymatdan ortiqchasi import qilinmaydi!</p>
                                </div>
                                <Form
                                    form={formSave}
                                    name="formSave"
                                    labelCol={{
                                        span: 24,
                                    }}
                                    wrapperCol={{
                                        span: 23,
                                    }}
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={saveAllStudents}
                                    autoComplete="off"
                                >
                                    <Row>
                                        <Col xl={5}>
                                            <Form.Item
                                                label="Savollar turi"
                                                name="type"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Savollar turini tanlang!',
                                                    },
                                                ]}
                                                initialValue={'single'}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Savol turini tanlang"
                                                    optionFilterProp="children"
                                                    defaultActiveFirstOption={true}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        questionTypes &&
                                                        questionTypes?.map((element, index) => {
                                                            return (
                                                                <Option key={index}
                                                                        value={element?.id}>{element?.name}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xl={5}>
                                            <Form.Item
                                                label="Savollar qiyinlik darajasi"
                                                name="question_degree_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Savollar qiyinlik darajasini tanlang!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Savol turini tanlang"
                                                    optionFilterProp="children"
                                                    defaultActiveFirstOption={true}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {

                                                        questionDegrees?.map((element, index) => {
                                                            return (
                                                                <Option key={index}
                                                                        value={element?.id}>{element?.name}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>

                                            </Form.Item>
                                        </Col>
                                        <Col xl={5}>
                                            <Form.Item
                                                label="Savollar tili"
                                                name="lang_id"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Savollar tilini tanlang!',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Savollar tilini tanlang"
                                                    optionFilterProp="children"
                                                    defaultActiveFirstOption={true}
                                                    filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                >
                                                    {
                                                        questionLang &&
                                                        questionLang?.map((element, index) => {
                                                            return (
                                                                <Option key={index}
                                                                        value={element?.id}>{element?.name}</Option>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </>
                        }
                        {
                            questions &&
                            questions.map((element, index) => {
                                return (
                                    <div
                                        className={`${element?.status === 0 ? 'error-color' : element?.status === 1 && element?.selected ? 'success-color' : 'waiting-color'} question-box`}>
                                        <div>
                                            <p><MathJaxWrapper>{element?.content}</MathJaxWrapper></p>
                                            <div className={'ps-3'}>
                                                <ul>
                                                    {
                                                        Object.entries(element?.answers).map(([key, value], indexAnswer) => {
                                                            return (
                                                                <li><MathJaxWrapper>{value}</MathJaxWrapper></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                element?.status === 1 &&
                                                <span className={'question-add-remove-button'}
                                                      onClick={() => questionAddRemove(element, index)}>
                                                    {
                                                        element?.selected ?
                                                            <Tooltip placement="topLeft"
                                                                     title={'Import qilinuvchilardan olib tashlash'}>
                                                                <MinusCircleOutlined/>
                                                            </Tooltip> :
                                                            <Tooltip placement="topLeft"
                                                                     title={'Import qilinuvchilarga qo`shish'}>
                                                                <PlusCircleOutlined/>
                                                            </Tooltip>
                                                    }
                                                    </span>

                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </Col>
                </Row>
            </Spin>
        </>
    )
}

export default QuestionImportIndex;