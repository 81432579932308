import React, {useContext} from 'react'
import Cookies from 'universal-cookie';
import {message} from "antd";
import API from "../../api";

export const organizationsIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/organization',
            method: 'GET',
            params:params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const organizationsStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/organization',
            method: 'POST',
            data: data,
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const organizationsUpdate = async (elementId, data = {},params={}) => {
    try {
        const response = await API({
            url: '/organization/'+elementId,
            method: 'PUT',
            data: data,
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const organizationsDelete = async (elementId,params={}) => {
    try {
        const response = await API({
            url: '/organization/' + elementId,
            method: 'DELETE',
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}