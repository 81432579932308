import React, { useContext } from "react";
import { privateRoutes, publicRoutes } from "../../../routes";
import { Menu, Layout } from "antd";
import { useNavigate } from "react-router-dom";
import MainContext from "../../../Utils/Context/MainContext";
import { LogoutOutlined } from "@ant-design/icons";
import { logoutFunction } from "../../../services/apiServices/auth/authApiFunctions";
import { Popconfirm } from "antd";

const { Sider } = Layout;
const Sidebar = (props) => {
  const { user, setIsAuth, setUser } = useContext(MainContext);
  let permissions = user?.permissions;
  const navigate = useNavigate();
  const { collapsed } = props;
  const routes = privateRoutes?.filter((e) => e?.isMenu);
  const noAuthRoutes = publicRoutes?.filter((e) => e?.isMenu);
  const logout = () => {
    (async () => {
      const response = await logoutFunction(setIsAuth, setUser);
      if (response?.status === 200) {
        navigate("/login");
      }
    })();
  };
  return (
    <>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{ height: "100vh", position: "sticky", top: "0" }}
      >
        <div className="logo" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={["1"]}
          className={"sidebar-scroll"}
        >
          {routes.map((element, index) => {
            if (
              permissions.includes(element?.permission) ||
              element?.permission == "*"
            ) {
              return (
                <Menu.Item
                  key={index}
                  onClick={() => navigate(element?.path)}
                  icon={element?.icon}
                >
                  {element?.name}
                </Menu.Item>
              );
            }
          })}
          {noAuthRoutes.map((element, index) => {
            return (
              <Menu.Item
                key={index}
                onClick={() => navigate(element?.path)}
                icon={element?.icon}
              >
                {element?.name}
              </Menu.Item>
            );
          })}

          <Menu.Item icon={<LogoutOutlined />}>
            <Popconfirm
              title="Chiqish"
              placement="right"
              okText="Ha"
              onConfirm={logout}
              cancelText="Yo'q"
            >
              Chiqish
            </Popconfirm>
          </Menu.Item>
        </Menu>
      </Sider>
    </>
  );
};

export default Sidebar;
