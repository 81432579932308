import React from "react";
import { Modal, Form, Input } from "antd";
import { message } from "antd";
import { organizationsStore } from "../../../../services/apiServices/organizations_api";

const OrganizationAddModal = (props) => {
  const { setIsVisibleAddModal, isVisibleAddModal, setReload, reload } = props;
  const [form] = Form.useForm();
  const handleOkAdd = () => {
    form.submit();
  };
  const handleCancelAdd = () => {
    setIsVisibleAddModal(false);
  };
  const onFinish = (values) => {
    const data = new FormData();
    for (const key in values) {
      data.append(key, values[key]);
    }
    (async () => {
      const response = await organizationsStore({}, data);

      if (response) {
        message.success("Ma`lumot saqlandi");
        form.resetFields();
        setIsVisibleAddModal(false);
        setReload(!reload);
      }
    })();
  };
  const onFinishFailed = (error) => {};
  return (
    <>
      <Modal
        title="Add organization"
        open={isVisibleAddModal}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        okText={"save"}
        cancelText={"cancel"}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input role name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[{ required: true, message: "Please input phone!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Address"
            name="address"
            rules={[{ required: true, message: "Please input address!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default OrganizationAddModal;
