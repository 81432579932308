import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Button, Pagination, Input } from "antd";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import {
  lessonsIndex,
  lessonsShow,
} from "../../../services/apiServices/lesson_api";
import ChapterAddModal from "../../Chapter/crud/Modals/ChapterAddModal";
import ChaptersTable from "../../Chapter/crud/ChaptersTable";
import BreadCrumbs from "../../../components/PageComponents/BreadCrumbs";
import MainContext from "../../../Utils/Context/MainContext";
import { chaptersIndex } from "../../../services/apiServices/chapters_api";

const LessonShow = () => {
  const { breadcrumbs, setBreadcrumbs } = useState([
    {
      title: "Fanlar",
    },
    {
      title: "Fanlar",
    },
  ]);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState();
  const [showCount, setShowCount] = useState(10);
  const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [lesson, setLesson] = useState();
  const navigate = useNavigate();
  const location = useParams();
  const showAddModal = () => {
    setIsVisibleAddModal(true);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let orderBy = {
        field: "name",
        value: "ASC",
      };
      let additional_relations = ["lesson"];
      let additional_relations_count = ["questions"];
      let filters = [
        {
          fieldKey: "lesson_id",
          fieldValue: location?.id,
        },
      ];
      let params = {
        show_count: showCount,
        page: page,
        search: search,
        orderBy: JSON.stringify(orderBy),
        additional_relations_count: JSON.stringify(additional_relations_count),
        additional_relations: JSON.stringify(additional_relations),
        filters: JSON.stringify(filters),
      };
      const response = await chaptersIndex(params);
      setData(response?.result);
      setIsLoading(false);
    })();
  }, [reload]);

  const changePage = (page, pageSize) => {
    setPage(page);
    setReload(!reload);
  };

  const changeWord = (e) => {
    setPage(1);
    setSearch(e?.target?.value);
    setReload(!reload);
  };

  return (
    <>
      <ChapterAddModal
        setIsVisibleAddModal={setIsVisibleAddModal}
        isVisibleAddModal={isVisibleAddModal}
        setReload={setReload}
        reload={reload}
        lessonId={location?.id}
      />
      <Row className={"d-flex justify-content-between"}>
        <div className={"d-flex"}>
          <span onClick={() => navigate(-1)}>
            <ArrowLeftOutlined className={"go-back-button"} />
          </span>
          <div>
            <h5>Boblar ({data?.lesson?.name})</h5>
            <BreadCrumbs breadcrumbs={breadcrumbs} />
          </div>
        </div>
        <div className={"d-flex"}>
          <Button
            type={"success"}
            onClick={showAddModal}
            className={"btn-outline-success"}
            icon={<PlusOutlined />}
          >
            {" "}
            Yangi qo'shish{" "}
          </Button>
        </div>
      </Row>
      <Row className={"d-flex justify-content-end mt-2"}>
        <Col xl={4}>
          <Input allowClear={true} onChange={changeWord} />
        </Col>
      </Row>
      <Row>
        <Col xl={24}>
          <ChaptersTable
            data={data?.data}
            isLoading={isLoading}
            setReload={setReload}
            reload={reload}
          />
        </Col>
        <Col xl={24} className={"d-flex justify-content-end mt-2"}>
          <Pagination
            defaultCurrent={data?.current_page}
            current={data?.current_page}
            total={data?.total}
            pageSize={showCount}
            onChange={changePage}
          />
        </Col>
      </Row>
    </>
  );
};

export default LessonShow;
