import React, {useContext} from 'react'
import Cookies from 'universal-cookie';
import {message} from "antd";
import API from "../../api";

export const studentEnterExam = async (data,params = {}) => {
    try {
        const response = await API({
            url: '/student-exam/enter',
            method: 'POST',
            params: params,
            headers: {
                'Accept': 'application/json'
            },
            data:data
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const studentStartExam = async (data,params = {}) => {
    try {
        const response = await API({
            url: '/student-exam/start',
            method: 'POST',
            params: params,
            headers: {
                'Accept': 'application/json'
            },
            data:data
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const studentEndExam = async (data,params = {}) => {
    try {
        const response = await API({
            url: '/student-exam/end',
            method: 'POST',
            params: params,
            headers: {
                'Accept': 'application/json'
            },
            data:data
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}