import React, {useState} from 'react'
import {Layout, Menu} from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import "./styles.css"
import Sidebar from "../sidebar/Sidebar";
import MyHeader from "../header/MyHeader";
import FooterStudentLayout from "../footer/FooterStudentLayout";
import StudentHeader from "../header/StudentHeader";

const {Header, Sider, Content} = Layout;


const StudentLayout = ({children}) => {

    const [collapsed, setCollapsed] = useState(false);

    const toggle = () => {
        setCollapsed(!collapsed);
    };
    return (
        <Layout>
            {/*<Sidebar collapsed={collapsed}/>*/}
            <Layout className="site-layout">
                <StudentHeader collapsed={collapsed} setCollapsed={setCollapsed} />
                <Content
                    className="site-layout-background"
                    style={{
                        margin: 'none',
                        padding:20,
                        minHeight: '100vh',
                        // backgroundColor:'red'
                    }}
                >
                    <div className={'pb-5'}>
                    {children}
                    </div>
                </Content>
                <FooterStudentLayout />
            </Layout>
        </Layout>
    );
}
export default StudentLayout