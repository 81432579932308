import React, {useContext} from 'react'
import {privateRoutes, publicRoutes} from "../index";
import {Route, Routes} from "react-router-dom"
import MyLayout from "../../components/structure/layout/MyLayout";
import NoneLayout from "../../components/structure/layout/NoneLayout";
import MainContext from "../../Utils/Context/MainContext";
import StudentLayout from "../../components/structure/layout/StudentLayout";
import NotFound from '../../pages/notFound';

const RouteMiddleware = () => {
    const {isAuth, user} = useContext(MainContext);
    let permissions = user?.permissions ? user?.permissions : [];
    const routes = isAuth ? privateRoutes : publicRoutes;
    const createComponent = (name) => {
        const NewComponent = name;
        return <NewComponent/>
    }

    return (
        <Routes>
            <>
                {
                    isAuth ?
                        routes.map((element, index) => {
                            // if (permissions?.length) {
                            if (permissions.includes(element?.permission) || element?.permission == '*') {
                                return (
                                    <Route key={index} path={element?.path} element={element?.withLayout ?
                                        element?.whichLayout === 'admins' ?
                                            <MyLayout>{createComponent(element?.component)}</MyLayout> :
                                            <StudentLayout>{createComponent(element?.component)}</StudentLayout> :
                                        <NoneLayout>{createComponent(element?.component)}</NoneLayout>}/>
                                )
                            }
                            // }
                        })
                        :
                        publicRoutes.map((element, index) => {
                            return (
                                <Route key={index} path={element?.path} element={element?.withLayout ?
                                    <MyLayout>{createComponent(element?.component)}</MyLayout> :
                                    <NoneLayout>{createComponent(element?.component)}</NoneLayout>}/>
                            )
                        })
                }
            </>
            <Route path='*' element={<NotFound/>} />
        </Routes>
    )
}
export default RouteMiddleware;