import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Pagination, Input, Select} from "antd";
import {NavLink} from "react-router-dom";
import {
    FileExcelOutlined,
    PlusOutlined,
    ArrowDownOutlined,
    ArrowUpOutlined
} from "@ant-design/icons"
import {topicsIndex} from "../../../services/apiServices/topics_api";
import {lessonsIndex} from "../../../services/apiServices/lesson_api";
import TopicAddModal from "./Modals/TopicAddModal";
import TopicsTable from "./TopicsTable";
import {chaptersIndex} from "../../../services/apiServices/chapters_api";

const TopicsIndex = () => {
    const {Option} = Select;
    const [data, setData] = useState([]);
    const [reload, setReload] = useState(false);
    const [search, setSearch] = useState();
    const [showCount, setShowCount] = useState(10);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [lessons, setLessons] = useState([]);
    const [chapters, setChapters] = useState([]);
    const [filterLessonId, setFilterLessonId] = useState();
    const [filterChapterId, setFilterChapterId] = useState();
    const showAddModal = () => {
        setIsVisibleAddModal(true);
    }
    useEffect(() => {
        (async () => {
            setIsLoading(true)
            let orderBy = {
                field: 'id',
                value: 'DESC'
            }
            let filters = [
                {
                    fieldKey: 'lesson_id',
                    fieldValue: filterLessonId
                }
            ]
            let params = {
                show_count: showCount,
                page: page,
                search: search,
                orderBy: JSON.stringify(orderBy)
            }
            if (filterLessonId) {
                Object.assign(params, {filters: JSON.stringify(filters)})
            }
            if (filterChapterId) {
                if (filterLessonId) {
                    filters = [
                        {
                            fieldKey: 'lesson_id',
                            fieldValue: filterLessonId
                        },
                        {
                            fieldKey: 'chapter_id',
                            fieldValue: filterChapterId
                        }
                    ]
                    Object.assign(params, {filters: JSON.stringify(filters)})
                }
            }
            const response = await topicsIndex(params);
            const lessonResponse = await lessonsIndex({show_count: 'all'});
            setLessons(lessonResponse?.result?.data);

            setData(response?.result);
            setIsLoading(false)
        })()
    }, [reload]);

    const changePage = (page, pageSize) => {
        setPage(page);
        setReload(!reload);
    }

    const changeWord = (e) => {
        setPage(1);
        setSearch(e?.target?.value);
        setReload(!reload);
    }

    const changeLesson = (selectedLessonId) => {
        if (selectedLessonId === '') {
            setFilterLessonId();
            setReload(!reload)
            setChapters([]);
            setFilterChapterId('')
            setFilterLessonId('')
        } else {
            setFilterLessonId(selectedLessonId);
            setReload(!reload);
            (async () => {
                let filters = [
                    {
                        fieldKey: 'lesson_id',
                        fieldValue: selectedLessonId
                    }
                ];
                let params = {
                    show_count: 'all',
                    filters: JSON.stringify(filters)
                }
                const chapterResponse = await chaptersIndex(params)
                setChapters(chapterResponse?.result?.data);
            })()
        }
    }
    const changeChapter = (selectedChapterId) => {
        if (selectedChapterId === '') {
            setFilterChapterId();
            setReload(!reload)

        } else {
            setFilterChapterId(selectedChapterId);
            setReload(!reload);
        }
    }
    return (
        <>
            <TopicAddModal setIsVisibleAddModal={setIsVisibleAddModal} isVisibleAddModal={isVisibleAddModal}
                           setReload={setReload} reload={reload} lessonId={''}/>
            <Row className={'d-flex justify-content-between'}>
                <h5>Mavzular</h5>
                <div className={'d-flex'}>
                    <Button type={'success'} onClick={showAddModal} className={'btn-outline-success'}
                            icon={<PlusOutlined/>}> Yangi qo'shish </Button>
                </div>
            </Row>
            <Row className={'d-flex justify-content-between mt-2'}>
                <Col xl={6}>
                    <Select
                        style={{width: '100%'}}
                        showSearch
                        placeholder="Fanni tanlang"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue={''}
                        onChange={changeLesson}
                        value={filterLessonId}
                    >
                        <Option value={''}>Barcha fanlar</Option>
                        {
                            lessons &&
                            lessons?.map((element, index) => {
                                return (
                                    <Option key={index} value={element?.id}>{element?.name}</Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col xl={6}>
                    <Select
                        style={{width: '100%'}}
                        showSearch
                        placeholder="Bobni tanlang"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        defaultValue={''}
                        onChange={changeChapter}
                        value={filterChapterId}
                    >
                        <Option value={''}>Barcha boblar</Option>
                        {
                            chapters &&
                            chapters?.map((element, index) => {
                                return (
                                    <Option key={index} value={element?.id}>{element?.name}</Option>
                                )
                            })
                        }
                    </Select>
                </Col>
                <Col xl={4}>
                    <Input allowClear={true} onChange={changeWord}/>
                </Col>
            </Row>
            <Row>
                <Col xl={24}>
                    <TopicsTable data={data} isLoading={isLoading} setReload={setReload} reload={reload}/>
                </Col>
                <Col xl={24} className={'d-flex justify-content-end mt-2'}>
                    <Pagination defaultCurrent={data?.current_page} current={data?.current_page} total={data?.total}
                                pageSize={showCount} onChange={changePage}/>

                </Col>
            </Row>
        </>
    )
}

export default TopicsIndex;