import React, {useContext} from 'react'
import API from "../../../api";
import Cookies from 'universal-cookie';
import {useNavigate} from "react-router-dom";
import {message} from "antd";
import axios from "axios";
import {PATH_PREFIX} from "../../../Utils/AppVariables";

const cookies = new Cookies();
export const loginFunction = async (data) => {
    try {
        const loginData = new FormData();
        loginData.append('username', data.username);
        loginData.append('password', data?.password);
        const response = await API({
            url: '/login',
            method: 'POST',
            headers: {
                'Content-Length': '0',
                'User-Agent': 'PostmanRuntime/7.29.2',
                'Accept-Encoding': 'gzip, deflate, br',
                'Connection': 'keep-alive',
                'Accept': 'application/json',
            },
            data: loginData
        });

        if (response.status === 200) {
            cookies.set('auth_token', response?.data?.token, {path: '/'});
        }
        if (response.status === 500) {
            message.error('dsssd')
        }
        if (response.status === 422) {
            message.error('dsssd')
        }
        return response;
    } catch (err) {
        // console.log('er res data' , err?.response)
        return err;
    }
}

export const registerFunction = async (data) => {
    try {
        const registerData = new FormData();
        registerData.append('username', data.username);
        registerData.append('password', data?.password);
        registerData.append('name', data.name)
        const response = await API({
            url: '/register',
            method: 'POST',
            headers: {
                'Content-Length': '0',
                'User-Agent': 'PostmanRuntime/7.29.2',
                'Accept-Encoding': 'gzip, deflate, br',
                'Connection': 'keep-alive',
                'Accept': 'application/json',
            },
            data: registerData
        });

        if (response.status === 200) {
            cookies.set('auth_token', response?.data?.token, {path: '/'});
        }
        if (response.status === 500) {
            message.error('dsssd')
        }
        if (response.status === 422) {
            message.error('dsssd')
        }
        return response;
    } catch (err) {
        // console.log('er res data' , err?.response)
        return err;
    }
}

export const meFunction = async () => {
    try {

        const response = await API({
            url: '/me',
            method: 'GET',
        });

        return response;
    } catch (err) {

    }
}

export const logoutFunction = async (setIsAuth, setUser) => {
    try {
        const response = await API({
            url: '/logout',
            method: 'POST',
        });
        if (response?.status === 200) {
            cookies.remove('auth_token');
            setIsAuth(false);
            setUser();
        }
        return response;
    } catch (err) {

    }
}