import React, {useState} from 'react'
import {Layout, Menu} from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
} from '@ant-design/icons';
import "./styles.css"

const {Header, Sider, Content} = Layout;


const NoneLayout = ({children}) => {

        return (
            <div className={'w-100  none-layout-background'}><div className={'blur-box'}>{children}</div></div>
        );
}
export default NoneLayout