import React from "react";
import {Form,Col,Row,Input,Select,TimePicker,DatePicker} from "antd"
import moment from "moment";
 const ExamShowForm = (props) => {
    const {form,data,disableAllInputs,groups,questionDegrees,langs} = props;
    const {Option} = Select;
    const { RangePicker } = DatePicker;
    return (
    <>
         <Form
                    form={form}
                    name="basic"
                    labelCol={{
                        span: 24,
                    }}
                    wrapperCol={{
                        span: 23,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    autoComplete="off"
                >
                    <Row>
                        <Col xl={6}>
                            <Form.Item
                                label="Imtihon nomi"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Imtihon nomini kiriting!',
                                    },
                                ]}
                                initialValue={data?.name}
                                value={'dsd'}
                            >
                                <Input disabled={disableAllInputs} value={'dsd'}/>
                            </Form.Item>
                        </Col>

                        <Col xl={6}>
                            <Form.Item
                                label="Urinishlar soni"
                                name="attempts_count"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Urinishlar sonini kiriting!',
                                    },
                                ]}
                            >
                                <Input disabled={disableAllInputs}/>
                            </Form.Item>
                        </Col>
                        <Col xl={6}>
                            <Form.Item
                                label="Imtihon vaqti davomiyligi"
                                name="duration"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Davomiylikni kiriting!',
                                    },
                                ]}
                                initialValue={moment('00:30:00', 'HH:mm:ss')}
                            >
                                <TimePicker disabled={disableAllInputs} style={{width: '100%'}}/>
                            </Form.Item>
                        </Col>
                        <Col xl={6}>
                            <Form.Item
                                label="Savollar qiyinlik darajasi"
                                name="question_degree_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Savollar qiyinlik darajasini tanlang!',
                                    },
                                ]}
                            >
                                <Select
                                    disabled={disableAllInputs}
                                    mode="multiple"
                                    showSearch
                                    placeholder="Tanlang"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }

                                >
                                    {
                                        questionDegrees?.map((element, index) => {
                                            return (
                                                <Option key={index} value={element?.id}>{element?.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={12}>
                            <Form.Item
                                label="Guruh"
                                name="group_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Guruhlarni tanlang!',
                                    },
                                ]}
                            >
                                <Select
                                    disabled={disableAllInputs}
                                    mode="multiple"
                                    allowClear
                                    style={{width: '100%'}}
                                    placeholder="Please select"
                                >
                                    {
                                        groups?.map((element, index) => {
                                            return (
                                                <Option key={index} value={element?.id}>{element?.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={6}>
                            <Form.Item
                                label="Imtihon holati"
                                name="status"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Imtihon holati!',
                                    },
                                ]}
                                initialValue={'active'}
                            >
                                <Select
                                    disabled={disableAllInputs}
                                    showSearch
                                    placeholder="Tanlang"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value={'active'}>Faol</Option>
                                    <Option value={'inactive'}>Faol emas</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={6}>
                            <Form.Item
                                label="Imtihon faol bo`lib turuvchi davr"
                                name="exam_start_end_time"

                            >
                                <RangePicker
                                    disabled={disableAllInputs}
                                    renderExtraFooter={() => 'extra footer'} showTime/>
                            </Form.Item>
                        </Col>
                        <Col xl={6}>
                            <Form.Item
                                label="Imtihon himoya holati"
                                name="is_protected"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Himoya holati!',
                                    },
                                ]}
                                initialValue={'0'}
                            >
                                <Select
                                    disabled={disableAllInputs}
                                    showSearch
                                    placeholder="Tanlang"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value={'1'}>Himoyalangan (Parol bilan kiriladi)</Option>
                                    <Option value={'0'}>Ochiq (Parolsiz kiriladi)</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={6}>
                            <Form.Item
                                label="Imtihon testlari yechilganidan keyin imtihon natijasida togri javoblar korsatilsinmi ?"
                                name="show_correct_answers"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tanlang!',
                                    },
                                ]}
                                initialValue={1}
                            >
                                <Select
                                    disabled={disableAllInputs}
                                    showSearch
                                    placeholder="Tanlang"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    <Option value={1}>Ha</Option>
                                    <Option value={0}>Yo`q</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xl={6}>
                            <Form.Item
                                label="Imtihon savollari tili"
                                name="lang_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Tili!',
                                    },
                                ]}
                            >
                                <Select
                                    disabled={disableAllInputs}
                                    showSearch
                                    placeholder="Tanlang"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {
                                        langs?.map((element, index) => {
                                            return (
                                                <Option value={element?.id} key={index}>{element?.name}</Option>
                                            )
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
    </>
    )
 }

 export default ExamShowForm;