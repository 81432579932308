import React, { useState, useEffect } from "react";
import { message, Modal, Row, Col, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { showPassword } from "../../../../services/apiServices/admins_api";

const GroupShowModal = (props) => {
  const { isShowModalVisible, setIsShowModalVisible, showElement } = props;
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const handleCancel = () => {
    setIsShowModalVisible(false);
  };
  const handleOk = () => {
    setIsShowModalVisible(false);
  };
  const showAdminPassword = () => {
    (async () => {
      const response = await showPassword(showElement?.user?.id);
      if (response) {
        setCredentials((prevState) => ({
          username: response?.result?.username,
          password: response?.result?.password,
        }));
      }
    })();
  };
  return (
    <>
      <Modal
        title="Admin show"
        open={isShowModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row>
          <Col xl={12}>
            <b>F.I.O:</b>
          </Col>
          <Col xl={12}>
            <p>{showElement?.fio}</p>
          </Col>
        </Row>
        <Row>
          <Col xl={12}>
            <b>Tashkilot:</b>
          </Col>
          <Col xl={12}>
            <p> {showElement?.organization?.name}</p>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default GroupShowModal;
