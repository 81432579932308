import React, {useContext} from 'react'
import API from "../../api";
import Cookies from 'universal-cookie';
import {message} from "antd";

export const questionIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/question',
            method: 'GET',
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const questionShow = async (params = {},elementId) => {
    try {
        const response = await API({
            url: '/question/'+elementId,
            method: 'GET',
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const questionStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/question',
            method: 'POST',
            data: data,
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}
export const questionUpdate = async (elementId, data = {},params = {}) => {
    try {
        const response = await API({
            url: '/question/' + elementId,
            method: 'POST',
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            data
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const questionDelete = async (elementId,params={}) => {
    try {
        const response = await API({
            url: '/question/' + elementId,
            method: 'DELETE',
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}

export const importQuestions = async (data,params={}) => {
    try {
        const response = await API({
            url: '/import-question',
            method: 'POST',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            data:data,
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}

export const saveImportQuestions = async (data,params={}) => {
    try {
        const response = await API({
            url: '/save-import-question',
            method: 'POST',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            data:data,
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}