import React, {useContext} from 'react'
import API from "../../../api";
import Cookies from 'universal-cookie';
import {useNavigate} from "react-router-dom";
import {message} from "antd";

const cookies = new Cookies();
export const loginExamFunction = async (data) => {
    try {
        const loginData = new FormData();
        loginData.append('username', data.username);
        const response = await API({
            url: '/login-exam',
            method: 'POST',
            data: loginData
        });
        if (response.status == 200) {
            cookies.set('auth_token', response?.data?.token, {path: '/'});
        }
        if (response.status == 500) {
            message.error('dsssd')
        }
        return response;

    } catch (err) {
        return err;
    }
}