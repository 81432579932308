/** @format */

import { Button, Result } from "antd";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import MainContext from "../../Utils/Context/MainContext";
import "./style.css";

const NotFound = () => {
  const navigate = useNavigate();
  const {isAuth, user} = useContext(MainContext);

  return (
    <div className='not-found'>
      <Result
        status='404'
        title='404'
        subTitle='Sorry, the page you visited does not exist.'
        extra={<Button type='primary' onClick={() => isAuth ? user.role === "student" ? navigate("/student/profile") : navigate("/") : navigate("/login")} >Back Home</Button>}
      />
    </div>
  );
};

export default NotFound
