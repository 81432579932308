import React, { useState, useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { message, Select } from "antd";
import { chaptersStore } from "../../../../services/apiServices/chapters_api";
import { lessonsIndex } from "../../../../services/apiServices/lesson_api";

const ChapterAddModal = (props) => {
  const {
    setIsVisibleAddModal,
    isVisibleAddModal,
    setReload,
    reload,
    lessonId,
  } = props;
  const [lessons, setLessons] = useState([]);
  const [form] = Form.useForm();
  const { Option } = Select;
  const handleOkAdd = () => {
    form.submit();
  };
  const handleCancelAdd = () => {
    setIsVisibleAddModal(false);
  };
  useEffect(() => {
    // if (lessonId === ''){
    (async () => {
      const response = await lessonsIndex({ show_count: "all" });
      if (response?.status == 1) {
        setLessons(response?.result?.data);
      }
    })();
    // }
  }, []);
  const onFinish = (values) => {
    const data = new FormData();
    for (const key in values) {
      data.append(key, values[key]);
    }
    (async () => {
      if (lessonId) {
        data.append("lesson_id", lessonId);
      }
      const response = await chaptersStore({}, data);

      if (response) {
        message.success("Ma`lumot saqlandi");
        form.resetFields();
        setIsVisibleAddModal(false);
        setReload(!reload);
      }
    })();
  };
  const onFinishFailed = (error) => {};
  return (
    <>
      <Modal
        title="Bob qo`shish"
        open={isVisibleAddModal}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        okText={"Saqlash"}
        cancelText={"Bekor qilish"}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {!lessonId && (
            <Form.Item
              label="Fan"
              name="lesson_id"
              rules={[{ required: true, message: "Fanni tanlang!" }]}
            >
              <Select
                showSearch
                placeholder="Fanni tanlang"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {lessons.map((element, index) => {
                  return (
                    <Option value={element?.id} key={index}>
                      {element?.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            label="Nomi"
            name="name"
            rules={[{ required: true, message: "Nomini kiriting!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChapterAddModal;
