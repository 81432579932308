import React, {useContext, useState} from 'react';
// import "./style.css"
import {Form, Input, Button, Spin, message} from "antd";
import {meFunction, registerFunction} from "../../services/apiServices/auth/authApiFunctions";
import MainContext from "../../Utils/Context/MainContext";
import {useNavigate, NavLink} from "react-router-dom";

const Registy = () => {
    const [form] = Form.useForm();
    const {isAuth, setIsAuth, user, setUser} = useContext(MainContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const onFinish = (values) => {
        (async () => {
            setIsLoading(true)
            const response = await registerFunction(values);
            if (response.status === 200) {
                setIsAuth(true);
                setUser(response.data.user);
                navigate('/');
            }
            else{
                // setIsLoading
            }
            setIsLoading(false)
        })()
    };

    const onFinishFailed = (errorInfo) => {

    };
    const submitForm = () => {
        form.submit();
    }
    const checkMe = () => {
        (async () => {
            let res = await meFunction();
        })()
    }
    return (
        <div className="container ">
            <Spin tip="Iltimos kuting" spinning={false}>
                <div className="form-box">
                    <div className="header-form">
                        <h4 className="text-primary text-center"><i className="fa fa-user-circle"
                                                                    style={{fontSize: "110px"}}></i></h4>
                        <div className="image w-100  text-center">
                            Ro'yxatdan o'tish
                        </div>
                    </div>
                    <div className="body-form">
                        <Form
                            form={form}
                            name="basic"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                label=""
                                name="name"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Ismingizni kiriting!',
                                    },
                                ]}
                            >
                                <Input placeholder="Ism"/>
                            </Form.Item>

                            <Form.Item
                                label=""
                                name="username"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Username kiriting!',
                                    },
                                ]}
                            >
                                <Input placeholder="Username"/>
                            </Form.Item>

                            <Form.Item
                                label=""
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Parolni kiriting!',
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Parol"/>
                            </Form.Item>
                            <Button onClick={submitForm} type="primary" loading={isLoading} className=" w-100 color-black"
                                    >Ro'yhatdan o'tish</Button>
                        </Form>
                        <div className="text-end mt-2 forRegis">
                            <NavLink to={'/login'}>
                                Avval kirganmisiz ?
                            </NavLink>
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default Registy;