import React, { useState } from "react";
import { Row, Button, Col, Upload, message, Form, Spin } from "antd";
import {
  InboxOutlined,
  ArrowLeftOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  importStudents,
  saveImportStudents,
} from "../../../services/apiServices/students_api";

const ImportIndex = () => {
  const [form] = Form.useForm();
  const { Dragger } = Upload;
  const navigate = useNavigate();
  const [fileList, setFileList] = useState([]);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const props = {
    name: "file",
    multiple: false,
    // action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
    onChange(info) {
      setFileList([
        {
          uid: "-1",
          name: info.file.name,
          status: "ok",
          url: URL.createObjectURL(info.file.originFileObj),
        },
      ]);
    },
    onDrop(e) {
      setFileList([
        {
          uid: "-1",
          name: e.dataTransfer.files[0]?.name,
          status: "ok",
          url: URL.createObjectURL(e.dataTransfer.files[0]),
        },
      ]);
      console.log("Dropped files", e.dataTransfer.files);
    },
    onRemove() {
      setFileList([]);
    },
  };
  const onFinish = (values) => {
    console.log("val", values);
    const formData = new FormData();
    formData.append("file", values?.file?.file?.originFileObj);
    (async () => {
      setIsLoading(true);
      const response = await importStudents(formData);
      if (response) {
        setData(response?.result);
        setIsLoading(false);
      }
    })();
  };
  const readFile = () => {
    form.submit();
  };
  const onFinishFailed = (er) => {
    console.log("val er", er);
  };
  let columns = [
    {
      title: "F.I.O",
      render: (index, element) => <>{element}</>,
    },
  ];
  const saveAllStudents = () => {
    if (data?.students?.length) {
      (async () => {
        setIsLoadingSpin(true);
        const response = await saveImportStudents(data?.students);
        if (response) {
          message.success("Ma`lumot saqlandi");
          setFileList([]);
          setData();
          navigate("/students");
        }
        setIsLoadingSpin(false);
      })();
    }
  };
  // console.log('dd' , data?.students)
  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <div className={"d-flex"}>
          <span onClick={() => navigate(-1)}>
            <ArrowLeftOutlined className={"go-back-button"} />
          </span>{" "}
          <h5>O'quvchilarni import qilish</h5>
        </div>
      </Row>
      <Spin tip="Ma`lumot saqlanmoqda" spinning={isLoadingSpin}>
        <Row>
          <Col xl={24}>
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label=""
                name="file"
                rules={[
                  {
                    required: true,
                    message: "Faylni tanlang!",
                  },
                ]}
              >
                <Dragger
                  customRequest={({ onSuccess }) => {
                    onSuccess("ok");
                  }}
                  {...props}
                  accept={".xls,.xlsx"}
                  fileList={fileList}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Buyerni bosib tanlang yoki excel faylni shu yerga tashlang{" "}
                    <br /> (
                    <span className={"text-danger"}>
                      Excel fayldan bir martada import qilish mumkin bo`lgan
                      o`quvchilarning maksimal soni 150 ta!. Undan oshgan
                      holatda ortiqchalari import qilinmaydi
                    </span>
                    )
                  </p>
                </Dragger>
              </Form.Item>
            </Form>
          </Col>
          <Col xl={24} className={"d-flex justify-content-end"}>
            <Button
              disabled={fileList?.length == 0 ? true : false}
              onClick={readFile}
              loading={isLoading}
            >
              Fayldagi ma`lumotlarni o`qish
            </Button>
          </Col>
        </Row>
      </Spin>
      <Spin tip="Ma`lumot o`qilmoqda" spinning={isLoading}>
        <Row className={"mt-3 border p-4"}>
          {data?.students && (
            <>
              <Col xl={12}></Col>
              <Col xl={12} className={"d-flex justify-content-end"}>
                <Button
                  className={"btn-outline-success"}
                  loading={isLoadingSpin}
                  disabled={isLoadingSpin}
                  onClick={saveAllStudents}
                >
                  Barcha o'quvchilarni saqlash
                </Button>
              </Col>
            </>
          )}
          <Col xl={24}>
            {data?.students && (
              <div className={"text-center"}>
                <p>
                  Import qilish mumkin bo'lgan maksimal o'quvchilar soni:{" "}
                  <b>{data?.maxSize}</b>
                </p>
                <p>
                  Fayldagi umumiy o'quvchilar soni: <b>{data?.count}</b>
                </p>
                <p className={"text-danger"}>
                  Maksimal qiymatdan ortiqchasi import qilinmaydi!
                </p>
              </div>
            )}
            {data?.students &&
              data?.students.map((element, index) => {
                return (
                  <>
                    <p>{element}</p>
                    <hr />
                  </>
                );
              })}
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default ImportIndex;
