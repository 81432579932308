import React, {useContext} from 'react'
import Cookies from 'universal-cookie';
import {message} from "antd";
import API from "../../api";

export const studentsIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/student',
            method: 'GET',
            params: params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const getNoGroupStudents = async (params = {}) => {
    try {
        const response = await API({
            url: '/get-not-grouped-students',
            method: 'GET',
            params: params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const studentsStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/student',
            method: 'POST',
            data: data,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const studentsUpdate = async (elementId, data = {},params={}) => {
    try {
        const response = await API({
            url: '/student/' + elementId,
            method: 'PUT',
            data: data,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const studentsDelete = async (elementId,params={}) => {
    try {
        const response = await API({
            url: '/student/' + elementId,
            method: 'DELETE',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}

export const importStudents = async (data,params={}) => {
    try {
        const response = await API({
            url: '/import-student',
            method: 'POST',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            data:data,
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}

export const saveImportStudents = async (data,params={}) => {
    try {
        const response = await API({
            url: '/save-import-student',
            method: 'POST',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            data:data,
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}

export const showPassword = async (userId,params={}) => {
    try {
        const response = await API({
            url: '/student-show-password/'+userId,
            method: 'GET',
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            },
            params
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (e) {

    }
}

export const studentsProfileIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/student-profile',
            method: 'GET',
            params: params,
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}


export const studentsResults = async (params = {}) => {
    try {
        const response = await API({
            url: '/student-get-exam-results',
            method: 'GET',
            params: params,
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
