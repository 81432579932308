import axios from "axios";
import { PATH_PREFIX } from "../Utils/AppVariables";
import Cookies from "universal-cookie";
import { message } from "antd";

const cookies = new Cookies();
// axios.defaults.withCredentials = true;
const API = axios.create({
  baseURL: PATH_PREFIX,
  // withCredentials: true,
});
API.defaults.withCredentials = true;
const onRequest = (config) => {
  const lastHeaders = {
    Accept: "application/json",
  };
  const lastParams = config.params ? config.params : {};
  if (cookies.get("auth_token")) {
    Object.assign(lastHeaders, {
      Authorization: "Bearer " + cookies.get("auth_token"),
    });
  }
  config.params = lastParams;
  config.headers = lastHeaders;
  return config;
};
const onRequestError = (err) => {};
API.interceptors.request.use(onRequest, onRequestError);
const onResponse = (response) => {
  return response;
};
const onResponseError = (error) => {
  let status = error?.response.status;
  if (status === 401) {
    cookies.remove("auth_token");
    window.location.href = "/login";
  }
  if (status === 500) {
    message.error(`${error?.response?.data?.message}`);
  }
  if (status === 403) {
    message.error("Bu amalni bajarish uchun huquqingiz yetarli emas");
  }
  if (status === 422) {
    console.log(error?.response?.data);
    for (const onRequestErrorKey in error?.response?.data?.errors) {
      error?.response?.data?.errors[onRequestErrorKey]?.map((element) => {
        message.error(element);
      });
    }
  }
};
API.interceptors.response.use(onResponse, onResponseError);
export default API;
