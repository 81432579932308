import React, { useEffect, useState } from "react";
import { Row, Col, Button, Pagination, Input } from "antd";
import { NavLink } from "react-router-dom";
import {
  FileExcelOutlined,
  PlusOutlined,
  ArrowUpOutlined,
} from "@ant-design/icons";
import { exportToExcel } from "../../../services/export/exportFunction";
import { groupsIndex } from "../../../services/apiServices/group_api";
import GroupsTable from "./GroupsTable";
const GroupsIndex = () => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState();
  const [showCount, setShowCount] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let params = {
        show_count: showCount,
        page: page,
        search: search,
      };
      const response = await groupsIndex(params);
      setData(response?.result);
      setIsLoading(false);
    })();
  }, [reload, page, showCount]);

  const changePage = (page, pageSize) => {
    setPage(page);
    setReload(!reload);
  };

  const changeWord = (e) => {
    setPage(1);
    setSearch(e?.target?.value);
    setReload(!reload);
  };
  const exportExcel = () => {
    let select = ["name as Nomi"];
    let params = {
      select: JSON.stringify(select),
    };
    exportToExcel("/group", "groups", params);
  };

  return (
    <>
      <Row className={"d-flex justify-content-between"}>
        <h5>Guruhlar</h5>
        <div className={"d-flex"}>
          <NavLink to={"/group-add"}>
            <Button className={"btn-outline-success"} icon={<PlusOutlined />}>
              {" "}
              Yangi qo'shish{" "}
            </Button>
          </NavLink>
          <Button
            type={"success"}
            onClick={exportExcel}
            icon={<ArrowUpOutlined />}
          >
            {" "}
            Excelga export qilish{" "}
            <FileExcelOutlined className={"text-success"} />{" "}
          </Button>
        </div>
      </Row>
      <Row className={"d-flex justify-content-end mt-2"}>
        <Col xl={4}>
          <Input allowClear={true} onChange={changeWord} />
        </Col>
      </Row>
      <Row>
        <Col xl={24}>
          <GroupsTable
            data={data?.data}
            isLoading={isLoading}
            setReload={setReload}
            reload={reload}
          />
        </Col>
        <Col xl={24} className={"d-flex justify-content-end mt-2"}>
          <Pagination
            defaultCurrent={data?.current_page}
            current={data?.current_page}
            total={data?.total}
            pageSize={showCount}
            onChange={changePage}
          />
        </Col>
      </Row>
    </>
  );
};

export default GroupsIndex;
