import React, { useState, useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { message, Select } from "antd";
import { topicsStore } from "../../../../services/apiServices/topics_api";
import { lessonsIndex } from "../../../../services/apiServices/lesson_api";
import { chaptersIndex } from "../../../../services/apiServices/chapters_api";

const TopicAddModal = (props) => {
  const {
    setIsVisibleAddModal,
    isVisibleAddModal,
    setReload,
    reload,
    chapterId,
  } = props;
  const [lessons, setLessons] = useState([]);
  const [chapters, setChapters] = useState([]);
  const { Option } = Select;
  const [form] = Form.useForm();
  const handleOkAdd = () => {
    form.submit();
  };
  const handleCancelAdd = () => {
    setIsVisibleAddModal(false);
  };
  useEffect(() => {
    (async () => {
      const lessonsResponse = await lessonsIndex({ show_count: "all" });
      setLessons(lessonsResponse?.result?.data);
    })();
  }, []);
  const onFinish = (values) => {
    const data = new FormData();
    for (const key in values) {
      data.append(key, values[key]);
    }
    if (chapterId) {
      data.append("chapter_id", chapterId);
    }
    (async () => {
      const response = await topicsStore({}, data);

      if (response) {
        message.success("Ma`lumot saqlandi");
        form.resetFields();
        setIsVisibleAddModal(false);
        setReload(!reload);
      }
    })();
  };
  const getChapters = (lesson_id) => {
    if (lesson_id) {
      (async () => {
        let filters = [
          {
            fieldKey: "lesson_id",
            fieldValue: lesson_id,
          },
        ];
        let params = {
          show_count: "all",
          filters: JSON.stringify(filters),
        };
        const chaptersResponse = await chaptersIndex(params);
        if (chaptersResponse?.status) {
          setChapters(chaptersResponse?.result?.data);
        }
      })();
    }
  };
  const onFinishFailed = (error) => {};

  function onChangeLesson(val) {
    getChapters(val);
  }

  return (
    <>
      <Modal
        title="Mavzu qo`shish"
        open={isVisibleAddModal}
        onOk={handleOkAdd}
        onCancel={handleCancelAdd}
        okText={"Saqlash"}
        cancelText={"Bekor qilish"}
      >
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {!chapterId && (
            <>
              <Form.Item label="Fan" name="lesson_id">
                <Select
                  showSearch
                  placeholder={"Fanni tanlang"}
                  optionFilterProp="children"
                  onChange={onChangeLesson}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {lessons &&
                    lessons?.map((element, index) => {
                      return (
                        <Option key={index} value={element?.id}>
                          {element?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item label="Bob" name="chapter_id">
                <Select
                  showSearch
                  placeholder={"Bobni tanlang"}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {chapters &&
                    chapters?.map((element, index) => {
                      return (
                        <Option key={index} value={element?.id}>
                          {element?.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item
            label="Nomi"
            name="name"
            rules={[{ required: true, message: "Nomini kiriting!" }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TopicAddModal;
