import React,{useState,useEffect} from "react";
import {Table} from "antd"

const ExamShowTable = (props) => {
    const {data , isLoading} = props;
    const columns = [
        {
            title:'F.I.O',
            render:(index,element) => <>{element?.student?.fio}</>
        },
         {
            title:'Unikal raqami',
             className:'text-center',
            render:(index,element) => <>{element?.student?.unique_number}</>,
        },
        {
            title:'To`g`ri javoblar soni',
            className: 'text-center',
            render:(index,element) => <>{element?.exam_student_attempt?.correct_answers_count}</>

        }
    ]
    return (
        <div className={'w-100'}>
            <Table columns={columns} dataSource={data} pagination={false}/>
        </div>
    )
}

export default ExamShowTable