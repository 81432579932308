import React, { useContext } from "react";
import Cookies from "universal-cookie";
import { message } from "antd";
import API from "../../api";

export const groupsIndex = async (params = {}) => {
  try {
    const response = await API({
      url: "/group",
      method: "GET",
      params: params,
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};

export const groupsShow = async (params = {}, elementID) => {
  try {
    const response = await API({
      url: "/group/" + elementID,
      method: "GET",
      params: params,
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
    });
    if (response.status === 200) {
      return response?.data;
    }
  } catch (err) {}
};

export const groupsStore = async (params = {}, data = {}) => {
  try {
    const response = await API({
      url: "/group",
      method: "POST",
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
      data: data,
      params,
    });
    if (response.status === 200) {
      return response?.data;
    }
  } catch (err) {}
};
export const updateGroupsStore = async (id, data = {}) => {
  try {
    const response = await API({
      url: "/group/" + id,
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      data: data,
    });
    if (response.status === 200) {
      return response?.data;
    }
  } catch (err) {}
};
export const groupsUpdate = async (elementId, data = {}, params = {}) => {
  try {
    const response = await API({
      url: "/group/" + elementId,
      method: "PUT",
      data: data,
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
      params,
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};
export const groupsDelete = async (elementId, params = {}) => {
  try {
    const response = await API({
      url: "/group/" + elementId,
      method: "DELETE",
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
      params,
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};

export const selectStudentByImport = async (data, params = {}) => {
  try {
    const response = await API({
      url: "/student-import-select",
      method: "POST",
      headers: {
        // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
        Accept: "application/json",
      },
      data: data,
      params,
    });
    if (response.status == 200) {
      return response?.data;
    }
  } catch (err) {}
};
