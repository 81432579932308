import React, {useContext} from 'react'
import API from "../../api";
import Cookies from 'universal-cookie';
import {message} from "antd";
import axios from "axios";

export const permissionsIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/permission',
            method: 'GET',
            params,

        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const permissionsStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/permission',
            method: 'POST',
            data: data,
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}
export const permissionsDelete = async (elementId,params = {}) => {
    try {
        const response = await API({
            url: '/permission/' + elementId,
            method: 'DELETE',
            params,
            headers: {
                // 'Authorization': 'Bearer ' + cookies.get('auth_token'),
                'Accept': 'application/json'
            }
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}