import React, {useState, useEffect} from 'react';
import './App.css';
import RouteMiddleware from "./routes/middleware/RouteMiddleware";
import 'bootstrap/dist/css/bootstrap.min.css';
import MainContext from "./Utils/Context/MainContext";
import {useNavigate} from "react-router-dom";
import Cookies from "universal-cookie";
import {meFunction} from "./services/apiServices/auth/authApiFunctions";
import "./style/style.css"

function App() {
    const cookie = new Cookies();
    const [isAuth, setIsAuth] = useState(false);
    const [user, setUser] = useState();
    const navigate = useNavigate();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    useEffect(() => {

        if (cookie.get('auth_token')) {
            (async () => {
                const res = await meFunction();
                if (res?.status == 200) {
                    setUser(res?.data?.user);
                    setIsAuth(true);
                }
                if (res?.status == 401) {
                    cookie.remove('auth_token');
                    setUser();
                    setIsAuth(false);
                    navigate('/login')
                }
            })()
        } else {
            console.log('path' ,window.location.pathname )
            if (window.location.pathname !== '/login' && window.location.pathname !== '/register') {
                navigate('/login');
            }
        }
    }, []);

    return (
        <MainContext.Provider
            value={{
                isAuth,
                setIsAuth,
                user,
                setUser,
                breadcrumbs,
                setBreadcrumbs
            }}>
            <RouteMiddleware/>
        </MainContext.Provider>
    );
}

export default App;
