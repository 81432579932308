import React, { useState } from "react";
import { Table, Popconfirm, message, Form } from "antd";
import {
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineExport,
} from "react-icons/ai";
import { groupsDelete } from "../../../services/apiServices/group_api";
import GroupEditModal from "./Modals/GroupEditModal";
import GroupShowModal from "./Modals/GroupShowModal";
import { NavLink } from "react-router-dom";
import { exportToExcel } from "../../../services/export/exportFunction";

const GroupsTable = (props) => {
  const [form] = Form.useForm();
  const { data, isLoading, setReload, reload } = props;
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedElement, setSelectedElement] = useState();
  const [showElement, setShowElement] = useState();
  const [isShowModalVisible, setIsShowModalVisible] = useState(false);
  const onClick = (id) => {
    exportToExcel(`/students/export-all?group_id=${id}`, `students-${id}`);
  };
  let columns = [
    {
      title: "Nomi",
      render: (index, element) => (
        <NavLink
          to={{
            pathname: `/groups/${element?.id}`,
          }}
        >
          {element?.name}
        </NavLink>
      ),
    },
    {
      title: "O`quvchilar soni",
      dataIndex: "students_count",
      key: "students_count",
    },
    {
      title: "Action",
      render: (_, element) => (
        <div className="action-box">
          <Popconfirm
            placement="left"
            title={"Ma`lumotni o`chirasizmi"}
            onConfirm={() => deleteElement(element?.id)}
            okText="Ha"
            cancelText="Yo`q"
          >
            <span className={"text-danger"}>
              <AiOutlineDelete />
            </span>
          </Popconfirm>
          <NavLink
            to={{
              pathname: `/groups/edit/${element?.id}`,
            }}
          >
            <span className={"text-warning"}>
              <AiOutlineEdit />
            </span>
          </NavLink>
          <span
            onClick={() =>
              exportToExcel(
                `get-group-students-password?group_id=${element?.id}`,
                "student",
                {}
              )
            }
          >
            <AiOutlineExport />
          </span>
        </div>
      ),
    },
  ];
  const deleteElement = (elementId) => {
    if (elementId) {
      (async () => {
        const response = await groupsDelete(elementId);
        if (response) {
          message.success("Ma`lumot o`chirildi");
          setReload(!reload);
        }
      })();
    }
  };
  const showEditModal = (element) => {
    form.setFieldsValue(element);
    setIsEditModalVisible(true);
    setSelectedElement(element);
  };
  const showElementFunction = (element) => {
    setShowElement(element);
    setIsShowModalVisible(true);
  };
  return (
    <>
      <GroupEditModal
        setIsEditModalVisible={setIsEditModalVisible}
        isEditModalVisible={isEditModalVisible}
        selectedElement={selectedElement}
        form={form}
        setReload={setReload}
        reload={reload}
      />
      <GroupShowModal
        setIsShowModalVisible={setIsShowModalVisible}
        isShowModalVisible={isShowModalVisible}
        showElement={showElement}
      />
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        loading={isLoading}
      />
    </>
  );
};

export default GroupsTable;
