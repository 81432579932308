import React, { useContext } from "react";
import { Layout, Menu, Dropdown, Button } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import MainContext from "../../../Utils/Context/MainContext";
import { logoutFunction } from "../../../services/apiServices/auth/authApiFunctions";
import { useNavigate } from "react-router-dom";

const { Header, Sider, Content } = Layout;
const MyHeader = ({ collapsed, setCollapsed }) => {
  const { user, setUser, isAuth, setIsAuth } = useContext(MainContext);
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const navigate = useNavigate();
  const logout = () => {
    (async () => {
      const response = await logoutFunction(setIsAuth, setUser);
      if (response?.status === 200) {
        navigate("/login");
      }
    })();
  };
  const menu = (
    <Menu>
      <Menu.Item>
        <span rel="noopener noreferrer" onClick={logout}>
          logout
        </span>
      </Menu.Item>
    </Menu>
  );
  return (
    <Header
      className="site-layout-background d-flex justify-content-between"
      style={{
        paddingLeft: "20px",
        position: "sticky",
        top: "0",
        zIndex: "1000",
        backgroundColor: "#001529",
      }}
    >
      <button
        className="bg-transparent border-0"
        style={{ color: "white" }}
        onClick={toggle}
      >
        {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </button>
      <span className="">
        <Dropdown overlay={menu} placement="bottomRight">
          <span style={{ cursor: "pointer", color: "white" }}>
            {user?.name}
          </span>
        </Dropdown>
      </span>
    </Header>
  );
};

export default MyHeader;
