import React, {useContext} from 'react'
import Cookies from 'universal-cookie';
import {message} from "antd";
import API from "../../api";

export const topicsIndex = async (params = {}) => {
    try {
        const response = await API({
            url: '/topic',
            method: 'GET',
            params: params,
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const topicsShow = async (params = {},elementId) => {
    try {
        const response = await API({
            url: '/topic/'+elementId,
            method: 'GET',
            params: params,
        });
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}

export const topicsStore = async (params = {}, data = {}) => {
    try {
        const response = await API({
            url: '/topic',
            method: 'POST',
            data: data,
            params,
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const topicsUpdate = async (elementId, data = {},params={}) => {
    try {
        const response = await API({
            url: '/topic/' + elementId,
            method: 'PUT',
            params,
            data: data
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {

    }
}
export const topicsDelete = async (elementId,params={}) => {
    try {
        const response = await API({
            url: '/topic/' + elementId,
            method: 'DELETE',
            params,
        })
        if (response.status == 200) {
            return response?.data;
        }
    } catch (err) {


    }
}