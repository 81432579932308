import React from "react";
import Nimadir from "../pages/Nimadir";
import Nimadir2 from "../pages/Nimadir2";
import Login from "../pages/Auth/Login";
import Register from "../pages/Register";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  HomeOutlined,
  TeamOutlined,
  ProfileOutlined,
  BookOutlined,
  FileProtectOutlined,
  DatabaseOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import RolesIndex from "../pages/Roles/RolesIndex";
import PermissionsIndex from "../pages/Permissions/PermissionsIndex";
import RoleShow from "../pages/Roles/RoleShow";
import OrganizationIndex from "../pages/Organization/crud/OrganizationIndex";
import AdminIndex from "../pages/Admins/crud/AdminIndex";
import StudentsIndex from "../pages/Students/crud/StudentsIndex";
import ImportIndex from "../pages/Students/import/ImportIndex";
import GroupsIndex from "../pages/Group/crud/GroupsIndex";
import GroupAdd from "../pages/Group/crud/GroupAdd";
import GroupShow from "../pages/Group/crud/GroupShow";
import LessonsIndex from "../pages/Lesson/crud/LessonsIndex";
import LessonShow from "../pages/Lesson/crud/LessonShow";
import ChaptersShow from "../pages/Chapter/crud/ChaptersShow";
import QuestionDegreeIndex from "../pages/QuestionDegree/QuestionDegreeIndex";
import LangsIndex from "../pages/LangCrud/LangsIndex";
import TopicShow from "../pages/Topic/crud/TopicShow";
import QuestionAdd from "../pages/Question/crud/QuestionAdd";
import QuestionShow from "../pages/Question/crud/QuestionShow";
import ExamIndex from "../pages/Exam/crud/ExamIndex";
import ExamAdd from "../pages/Exam/crud/ExamAdd";
import LoginExam from "../pages/Students/Exam/LoginExam";
import ExamIndexStudent from "../pages/Students/StudentPages/Exam/ExamIndexStudent";
import ExamShowStudent from "../pages/Students/StudentPages/Exam/ExamShowStudent";
import ExamProcess from "../pages/Students/StudentPages/Exam/ExamProcess";
import ChaptersIndex from "../pages/Chapter/crud/ChaptersIndex";
import TopicsIndex from "../pages/Topic/crud/TopicsIndex";
import ProfileIndex from "../pages/Students/StudentPages/profile/ProfileIndex";
import ExamShow from "../pages/Exam/crud/ExamShow";
import QuestionImportIndex from "../pages/Question/crud/import/QuestionImportndex";
import Registy from "../pages/Registy/Registy";
import UpdateQuestion from "../pages/Question/crud/QuestionEdit";
import GroupEdit from "../pages/Group/crud/GroupEdit";
import ExamResult from "../pages/Students/StudentPages/examResult";

const privateRoutes = [
  {
    name: "Bosh sahifa",
    path: "/",
    component: Nimadir,
    icon: <HomeOutlined />,
    classname: "class",
    isMenu: true,
    exact: true,
    permission: "*",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Roles",
    path: "/roles",
    component: RolesIndex,
    icon: <UserOutlined />,
    classname: "",
    isMenu: true,
    exact: true,
    permission: "role index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Permissions",
    path: "/permissions",
    component: PermissionsIndex,
    icon: <UserOutlined />,
    classname: "",
    isMenu: true,
    exact: true,
    permission: "permission index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Role show",
    path: "/roles/:id",
    component: RoleShow,
    icon: <UserOutlined />,
    classname: "",
    isMenu: false,
    exact: true,
    permission: "role show",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Organizations",
    path: "/organizations",
    component: OrganizationIndex,
    icon: <UserOutlined />,
    classname: "",
    isMenu: true,
    exact: true,
    permission: "organization index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Admins",
    path: "/admins",
    component: AdminIndex,
    icon: <UserOutlined />,
    classname: "admin index",
    isMenu: true,
    exact: true,
    permission: "admin index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "O`quvchilar",
    path: "/students",
    component: StudentsIndex,
    icon: <TeamOutlined />,
    classname: "student index",
    isMenu: true,
    exact: true,
    permission: "student index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "import students",
    path: "/import-students",
    component: ImportIndex,
    icon: <UserOutlined />,
    classname: "student index",
    isMenu: false,
    exact: true,
    permission: "student import",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Guruhlar",
    path: "/groups",
    component: GroupsIndex,
    icon: <ProfileOutlined />,
    classname: "group index",
    isMenu: true,
    exact: true,
    permission: "group index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Guruh",
    path: "/groups/:id",
    component: GroupShow,
    icon: <UserOutlined />,
    classname: "group show",
    isMenu: false,
    exact: true,
    permission: "group show",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Guruh",
    path: "/groups/edit/:id",
    component: GroupEdit,
    icon: <UserOutlined />,
    classname: "group edit",
    isMenu: false,
    exact: true,
    permission: "group edit",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Guruh qo`shish",
    path: "/group-add",
    component: GroupAdd,
    icon: <UserOutlined />,
    classname: "group store",
    isMenu: false,
    exact: true,
    permission: "group store",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Fanlar",
    path: "/lessons",
    component: LessonsIndex,
    icon: <BookOutlined />,
    classname: "lesson index",
    isMenu: true,
    exact: true,
    permission: "lesson index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Boblar",
    path: "/chapters",
    component: ChaptersIndex,
    icon: <DatabaseOutlined />,
    classname: "chapter index",
    isMenu: true,
    exact: true,
    permission: "chapter index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Mavzular",
    path: "/topics",
    component: TopicsIndex,
    icon: <ContainerOutlined />,
    classname: "topic index",
    isMenu: true,
    exact: true,
    permission: "topic index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "lessons show",
    path: "/lessons/:id",
    component: LessonShow,
    icon: <UserOutlined />,
    classname: "lesson show",
    isMenu: false,
    exact: true,
    permission: "lesson show",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "chapters show",
    path: "/chapters/:id",
    component: ChaptersShow,
    icon: <UserOutlined />,
    classname: "chapter show",
    isMenu: false,
    exact: true,
    permission: "chapter show",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Question degree",
    path: "/question-degree",
    component: QuestionDegreeIndex,
    icon: <UserOutlined />,
    classname: "question degree menu",
    isMenu: true,
    exact: true,
    permission: "question degree menu",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Langs",
    path: "/lang",
    component: LangsIndex,
    icon: <UserOutlined />,
    classname: "lang menu",
    isMenu: true,
    exact: true,
    permission: "lang menu",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "topic show",
    path: "/topics/:id",
    component: TopicShow,
    icon: <UserOutlined />,
    classname: "topic show",
    isMenu: false,
    exact: true,
    permission: "topic show",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "question store",
    path: "/add-question/:id",
    component: QuestionAdd,
    icon: <UserOutlined />,
    classname: "question store",
    isMenu: false,
    exact: true,
    permission: "question store",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Update question",
    path: "/update_question/:id",
    component: UpdateQuestion,
    icon: <UserOutlined />,
    classname: "question store",
    isMenu: false,
    exact: true,
    permission: "question store",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "question show",
    path: "/questions/:id",
    component: QuestionShow,
    icon: <UserOutlined />,
    classname: "question show",
    isMenu: false,
    exact: true,
    permission: "question show",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Imtihonlar",
    path: "/exams",
    component: ExamIndex,
    icon: <FileProtectOutlined />,
    classname: "exam index",
    isMenu: true,
    exact: true,
    permission: "exam index",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Imtihonni ko`rish",
    path: "/exams/:id",
    component: ExamShow,
    icon: <FileProtectOutlined />,
    classname: "exam show",
    isMenu: false,
    exact: true,
    permission: "exam show",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Imtihon qo`shish",
    path: "/exams-add",
    component: ExamAdd,
    icon: <UserOutlined />,
    classname: "exam store",
    isMenu: false,
    exact: true,
    permission: "exam store",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
  {
    name: "Imtihonlar",
    path: "/student/exams",
    component: ExamIndexStudent,
    icon: <UserOutlined />,
    classname: "student exam index",
    isMenu: true,
    exact: true,
    permission: "student exam index",
    withLayout: true,
    whichLayout: "students",
    submenu: [],
  },
  {
    name: "exam show student",
    path: "/student/exams/:id",
    component: ExamShowStudent,
    icon: <UserOutlined />,
    classname: "student exam show",
    isMenu: false,
    exact: true,
    permission: "student exam show",
    withLayout: true,
    whichLayout: "students",
    submenu: [],
  },
  {
    name: "exam start student",
    path: "/student-exam/start/:id",
    component: ExamProcess,
    icon: <UserOutlined />,
    classname: "student exam start",
    isMenu: false,
    exact: true,
    permission: "student exam start",
    withLayout: true,
    whichLayout: "students",
    submenu: [],
  },
  {
    name: "Profil",
    path: "/student/profile",
    component: ProfileIndex,
    icon: <UserOutlined />,
    classname: "student profile",
    isMenu: true,
    exact: true,
    permission: "student profile",
    withLayout: true,
    whichLayout: "students",
    submenu: [],
  },
  {
    name: "Result",
    path: "/student/results",
    component: ExamResult,
    icon: <UserOutlined />,
    classname: "student profile",
    isMenu: true,
    exact: true,
    permission: "student profile",
    withLayout: true,
    whichLayout: "students",
    submenu: [],
  },
  {
    name: "question import",
    path: "/import-question/:id",
    component: QuestionImportIndex,
    icon: <UserOutlined />,
    classname: "question import",
    isMenu: false,
    exact: true,
    permission: "question import",
    withLayout: true,
    whichLayout: "admins",
    submenu: [],
  },
];
const publicRoutes = [
  // {
  //     name: 'Registr',
  //     path: '/registr',
  //     component: Register,
  //     icon: 'fa fa-birnarsa2',
  //     classname: 'class2',
  //     isMenu: false,
  //     exact: true,
  //     withLayout: false,
  // },
  // {
  //     name: 'Login',
  //     path: '/login-exam',
  //     component: LoginExam,
  //     icon: '',
  //     classname: '',
  //     isMenu: false,
  //     exact: true,
  //     withLayout: false,
  // },
  {
    name: "Registy",
    path: "/register",
    component: Registy,
    icon: "",
    classname: "",
    isMenu: false,
    exact: true,
    withLayout: false,
  },
  {
    name: "Login",
    path: "/login",
    component: Login,
    icon: "",
    classname: "",
    isMenu: false,
    exact: true,
    withLayout: false,
  },
];
export { privateRoutes, publicRoutes };
