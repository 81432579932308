import React, {useEffect, useState} from 'react'
import {Row, Col, Button, Pagination, Input, Form, Upload, message} from "antd";
import {NavLink, useNavigate} from "react-router-dom";
import {
    ArrowLeftOutlined,
    UploadOutlined
} from "@ant-design/icons"
import AllStudentsTable from "./AddTables/AllStudentsTable";
import SelectedStudentsTable from "./AddTables/SelectedStudentsTable";
import {groupsStore, selectStudentByImport} from "../../../services/apiServices/group_api";

const GroupAdd = () => {
    const [formAdd] = Form.useForm();
    const [formUpload] = Form.useForm();
    const [allStudents, setAllStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [isLoadingImport, setIsLoadingImport] = useState(false);
    const navigate = useNavigate();

    const props = {
        name: 'file',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
            } else if (info.file.status === 'error') {
            }
        },
    };
    const selectByImport = () => {
        formUpload.submit();
    }
    const onFinishUpload = (values) => {
        const data = new FormData();
        data.append('file', values?.file?.file?.originFileObj);
        (async () => {
            setIsLoadingImport(true)
            const res = await selectStudentByImport(data);
            let rrr = res?.result?.filter(function (elem) {
                return !selectedKeys.includes(elem?.unique_number);
            })
            let t = [...selectedStudents, ...rrr];
            setSelectedStudents(t);
            let ids = t.map((elem, index) => {
                return elem?.unique_number;
            })
            setSelectedKeys(ids);
            setIsLoadingImport(false)

        })()
    }
    const saveGroup = () => {
        formAdd.submit();
    }
    const onFinish = (values) => {
        if (selectedKeys?.length > 0) {

            (async () => {
                const data = new FormData();
                for (const key in values) {
                    data.append(key, values[key]);
                }
                data.append('student_unique_numbers', JSON.stringify(selectedKeys));
                const res = await groupsStore({}, data);
                if (res) {
                    message.success('Guruh yaratildi')
                    navigate('/groups')
                }
            })()
        } else {
            message.error('O`quvchi tanlanmagan')
        }

    }
    return (
        <>
            <Row className={'d-flex justify-content-between'}>
                <div className={'d-flex'}>
                 <span onClick={() => navigate(-1)}>
                     <ArrowLeftOutlined className={'go-back-button'}/>
                 </span>
                    <h5>Guruh qo'shish</h5>
                </div>
                <div className={'d-flex'}>

                </div>
            </Row>

            <Form
                form={formAdd}
                name="basic"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 23,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Row>
                    <Col xl={6}>
                        <Form.Item
                            label="Guruh nomi"
                            name="name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Guruh nomini kiriting!',
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col xl={4}>
                        {/*<Form.Item*/}
                        {/*    label="Guruh uchun tanlangan o`quvchilar soni"*/}
                        {/*    name="count"*/}
                        {/*>*/}
                        {/*    <Input disabled={true} value={selectedStudents?.length} />*/}
                        {/*</Form.Item>*/}
                    </Col>
                    <Col xl={6}>

                    </Col>
                    <Col xl={6}>
                    </Col>
                    <Col xl={2} className={'d-flex justify-content-end'}>
                        <Button className={'btn-outline-success'} onClick={saveGroup}>Saqlash</Button>
                    </Col>
                </Row>
            </Form>
            <Form
                form={formUpload}
                name="import"
                labelCol={{
                    span: 24,
                }}
                wrapperCol={{
                    span: 23,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinishUpload}
                autoComplete="off"
            >
                <Row>
                    <Col xl={12}>
                        <span><b>O'quvchilarni tanlash</b></span>
                    </Col>
                    <Col xl={12}>
                        <span><b>Tanlanganlar</b></span>

                    </Col>
                    <Col xl={12} className={'p-1 border'}>
                        <AllStudentsTable
                            allStudents={allStudents}
                            setAllStudents={setAllStudents}
                            selectedStudents={selectedStudents}
                            setSelectedStudents={setSelectedStudents}
                            setSelectedKeys={setSelectedKeys}
                            selectedKeys={selectedKeys}
                        />
                    </Col>

                    <Col xl={12} className={'p-1 border'}>
                        <div className={'d-flex'}>
                            <Form.Item
                                label=""
                                name="file"
                            >
                                <Upload {...props} accept={'.xls,.xlsx'} customRequest={({onSuccess}) => {
                                    onSuccess('ok')
                                }}>
                                    <Button loading={isLoadingImport} icon={<UploadOutlined/>}>Excel file dan unikal raqamlar orqali import
                                        qilish <span className={'text-danger'}>Maksimal soni : 50!</span></Button>
                                </Upload>
                            </Form.Item>
                            <Button onClick={selectByImport} loading={isLoadingImport} disabled={isLoadingImport}>Import</Button>
                        </div>
                        <SelectedStudentsTable
                            isLoadingImport={isLoadingImport}
                            allStudents={allStudents}
                            setAllStudents={setAllStudents}
                            selectedStudents={selectedStudents}
                            setSelectedStudents={setSelectedStudents}
                            setSelectedKeys={setSelectedKeys}
                            selectedKeys={selectedKeys}
                        />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default GroupAdd;